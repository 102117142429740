import React from "react";
import image1 from "../image/Aim.png";
import { AiFillCodepenCircle } from "react-icons/ai";

const CourseOverview = () => {
  return (
    <div className="h-auto flex flex-col md:flex-row items-center justify-center p-4 md:p-6">
      <div className="w-full md:w-1/2 flex items-center justify-center p-4">
        <img src={image1} alt="Scouts Guide" className="h-[600px] w-[500px]" />
      </div>
      <div className="p-4 md:p-8 w-full md:w-1/2">
        <h1 className="text-blue-800 text-2xl sm:text-3xl md:text-4xl font-semibold font-Calibribold mb-4">
          AIM AND OBJECTIVES OF THE COURSE:
        </h1>
        <div className="border-t-4 border-white w-24 sm:w-32 mb-4"></div>

        <p className="text-sm sm:text-md md:text-lg mb-4">
          Scouting / Guiding is a claim of every youth. It is the duty of adults to facilitate the romance of Scouting / Guiding for young people. In order to achieve the growth of The Bharat Scouts and Guides, there is a need for more Unit Leaders. But the Leaders are unable to run the units for the simple reason that they have not enough time to undergo the Basic Course, thereby, thousands of young people are in a queue to enjoy the Scouting / Guiding. This Online Course is a route map to help the adults to start and run a unit, immediately after successful completion of the Online Beginners Course.
        </p>

        <p className="text-sm sm:text-md md:text-lg mb-4">
          Scouting / Guiding is a noble organisation. God has selected you and given a chance to you to serve the young people. Let us join hands together to convert our dreams into reality.
        </p>

        <h2 className="text-sm sm:text-md md:text-xl font-bold mb-4">
          Objective Of Course
        </h2>
        {[
          "understand the concept of Scouting / Guiding.",
          "recognize the need of Scouting / Guiding in present society.",
          "start and run a new unit."
        ].map((objective, index) => (
          <p key={index} className="flex items-center space-x-2 mb-2">
            <span className="text-black">
              <AiFillCodepenCircle />
            </span>
            <span className="text-gray-800">{objective}</span>
          </p>
        ))}
      </div>
    </div>
  );
};

export default CourseOverview;
