import React from "react";
import Navbar from "../Navbar";
import Image from "../../image/WHAT IS SCOUTING GUIDING.webp";
import images from "../../image/Scouting-Educational.webp";
import backgroundImage from "../../image/newprofile.png";
import Pagination from "../Pagination";

const Content2 = () => {
  return (
    <>
      <div
        className="h-[480px] text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Navbar />
      </div>

      <div className="px-4 sm:px-8 md:px-16 lg:px-32 py-12 sm:py-16 lg:py-20">
        <div className="-mt-32 sm:-mt-40 lg:-mt-96">
          <div className="max-w-full lg:w-[1024px] mx-auto justify-center items-center">
            <img className="w-full" src={Image} alt="scouting" />
          </div>
        </div>
        <div className="min-h-screen p-6">
          <header className="pt-2">
          
          </header>

          <main className="mt-6 space-y-6 text-gray-800">
            <section>
              <h2 className="text-xl font-semibold mb-2">SCOUTING / GUIDING IS -</h2>
              <div className="list-disc list-inside space-y-2 px-8 font-semibold uppercase text-gray-800">
                <p>✥ Education for Life</p>
                <p>✥ A Game</p>
                <p>✥ A Plan</p>
                <p>✥ Fun with Purpose</p>
                <p>✥ A Method</p>
                <p>✥ A Science of Outing</p>
                <p>✥ A Programme</p>
                <p>✥ A Movement for Youth</p>
                <p>✥ International</p>
                <p>✥ Growing</p>
                <p>✥ Open for All</p>
                <p>✥ Voluntary</p>
                <p>✥ Non-Political</p>
                <p>✥ Non-Governmental</p>
                <p>✥ Adventure</p>
                <p>✥ An Opportunity for Adults</p>
              </div>
            </section>

            <section>
              <div className="list-disc list-inside space-y-2 px-2 sm:px-8 text-lg font-bold uppercase text-gray-800">
                <p>✥ WE ACHIEVE - YOUTH EMPOWERMENT</p>
                <p>✥ WE CREATE - ACTIVE CITIZENS</p>
                <p>✥ WE CULTIVATE - LIFELONG VALUES AND SKILLS</p>
                <p>✥ WE ENGAGE - IN PEACE EDUCATION</p>
              </div>
            </section>

            <section>
              <h2 className="text-lg font-bold mb-2">FOUR PILLARS OF SCOUTING / GUIDING</h2>
              <div className="list-disc list-inside space-y-2 px-8 font-medium uppercase text-gray-800">
                <p>⛯ Character and Intelligence</p>
                <p>⛯ Health and Strength</p>
                <p>⛯ Handicrafts and Skills</p>
                <p>⛯ Service</p>
              </div>
            </section>
          </main>
          
          <div className="w-full lg:w-full mx-auto justify-center items-center rounded-md mt-10">
            <img className="w-full" src={images} alt="scouting method" />
          </div>
        </div>
        <Pagination />
      </div>
    </>
  );
};

export default Content2;
