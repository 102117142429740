import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Navbar from "./Navbar";
import { GET_CHALLENGE } from "../constant/constant";
import { BASE_URL, GET_USER } from "../constant/constant";
const Challanges = () => {
  const navigate = useNavigate();
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const getSessionItemWithExpiry = (key) => {
          const itemStr = sessionStorage.getItem(key);
          if (!itemStr) return null;

          const item = JSON.parse(itemStr);
          const now = new Date();
          if (now.getTime() > item.expiry) {
            sessionStorage.removeItem(key);
            return null;
          }

          return item.value;
        };

        const userId = getSessionItemWithExpiry("userId");
        if (!userId) {
          throw new Error("User ID not found in session.");
        }
        const userResponse = await axios.get(`${GET_USER}/${userId}`);
        const user = userResponse.data.user;
        console.log(userResponse.data);
        const requiredFields = [
          "name",
          "fatherName",
          "motherName",
          "address",
          "state",
          "city",
          "pincode",
          "educationQualification",
          "email",
          "phone",
          "gender",
        ];

        const isProfileComplete = requiredFields.every(
          (field) => user[field] && user[field].toString().trim() !== ""
        );

        if (!isProfileComplete) {
          Swal.fire({
            title: "Incomplete Profile",
            text: "Please complete your profile before proceeding.",
            icon: "warning",
            confirmButtonText: "Go to Profile",
            customClass: {
              popup: "rounded-lg shadow-md",
              title: "font-semibold text-lg",
              confirmButton:
                "bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700",
            },
          }).then(() => {
            navigate("/profile");
          });
          return;
        }
        const chaptersResponse = await axios.get(`${GET_CHALLENGE}/${userId}`);
        setChapters(chaptersResponse.data.data);
        setLoading(false);
      } catch (err) {
        console.error(err.message);
        setError("Failed to fetch chapters or user data");
        setLoading(false);
      }
    };

    fetchUserData();
  }, [navigate]);
  useEffect(() => {
    if (!loading && chapters.length > 0) {
      const allSubmitted = chapters.every((chapter) => chapter.attempted);
      if (allSubmitted) {
        Swal.fire({
          title: 'Quiz Completed!',
          text: 'You have successfully completed the quiz.',
          icon: 'success',
          confirmButtonText: 'Go to Certificates',
          customClass: {
            popup: 'my-swal-popup',
            title: 'my-swal-title',  
            content: 'my-swal-content', 
            confirmButton: 'my-swal-confirm-button', 
          },
          didOpen: () => {
            const popup = document.querySelector('.swal2-popup');
            if (popup) {
              popup.style.backgroundColor = '#f0f8ff';
              popup.style.borderRadius = '10px';
              popup.style.padding = '20px';
            }
  
            const title = document.querySelector('.swal2-title');
            if (title) {
              title.style.fontSize = '24px';
              title.style.fontWeight = 'bold';
              title.style.color = '#4CAF50';
            }
  
            const content = document.querySelector('.swal2-content');
            if (content) {
              content.style.fontSize = '16px';
              content.style.color = '#555';
            }
  
            const confirmButton = document.querySelector('.swal2-confirm');
            if (confirmButton) {
              confirmButton.style.backgroundColor = '#4CAF50'; 
              confirmButton.style.color = 'white';
              confirmButton.style.border = 'none';
              confirmButton.style.borderRadius = '5px';
              confirmButton.style.padding = '10px 20px';
              confirmButton.style.fontSize = '16px';
              
              // Button hover effect
              confirmButton.onmouseover = () => {
                confirmButton.style.backgroundColor = '#45a049'; 
              };
              confirmButton.onmouseout = () => {
                confirmButton.style.backgroundColor = '#4CAF50'; 
              };
            }
          }
        }).then((result) => {
          if (result.isConfirmed) {
            navigate('/certificates');
          }
        });
      }
    }
  }, [chapters, loading, navigate]);
  
  const handleAttemptClick = (chapter) => {
    navigate(`/challenges/${chapter}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="bg-[#1D57A5] h-64 text-white">
        <Navbar />
        <h1 className="font-semibold text-2xl md:text-3xl mt-3 text-center text-white">
          BEGINNERS COURSE
        </h1>
        <h1 className="font-medium text-base md:text-xl text-center mt-3 px-4 md:px-0 text-white">
          Once you start any one of the Challenges, you have to complete the
          rest of the challenges within 15 days.
        </h1>
      </div>
      <div className="pt-8 pb-10 px-6 sm:px-10 md:px-20 lg:px-28 bg-gray-100">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
          {chapters.map((chapter, index) => (
            <div
              key={index}
              className="bg-[#1D57A5] rounded-lg p-4 sm:p-6 shadow-lg flex flex-col items-center"
            >
              <div className="w-12 h-12 md:w-16 md:h-16 bg-white text-blue-700 rounded-full flex items-center justify-center text-lg md:text-3xl font-bold mb-4">
                {index + 1}
              </div>

              <h3 className="text-white text-base md:text-lg font-semibold text-center mb-2">
                {chapter.chapter}
              </h3>

              <div className="border-t border-dotted border-white w-full my-2"></div>

              <p className="text-white text-center text-sm mt-2">
                Total Questions = 10
              </p>

              <div className="border-t border-dotted border-white w-full my-2"></div>

              <p className="text-white text-center text-sm font-semibold">
                {chapter.totalScore === false
                  ? "Unlimited Attempts Available"
                  : `Score: ${chapter.totalScore}%`}
              </p>

              <button
                onClick={() => handleAttemptClick(chapter.chapter)}
                className={`mt-4 w-full py-2 rounded-lg text-sm font-semibold transition duration-300 ${
                  chapter.attempted
                    ? "bg-yellow-400  text-gray-500 cursor-not-allowed"
                    : "bg-white text-black hover:bg-gray-200"
                }`}
                disabled={chapter.attempted}
              >
                {chapter.attempted ? "Submitted" : "Attempt"}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Challanges;
