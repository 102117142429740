import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import backgroundImage from "../image/profile-bg.png";
import Swal from "sweetalert2";
import { BASE_URL, GET_USER } from "../constant/constant";
const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [preview, setPreview] = useState(null); 
 const navigate=useNavigate();
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
    
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl); 
    }
  };
  const [formData, setFormData] = useState({
    fatherName: "",
    motherName: "",
    phone: "",
    date: "",
    address: "",
    state: "",
    city: "",
    pincode: "",
    educationQualification: "",
  });

  

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const getSessionItemWithExpiry = (key) => {
          const itemStr = sessionStorage.getItem(key);
          if (!itemStr) return null;
          const item = JSON.parse(itemStr);
          const now = new Date();
          if (now.getTime() > item.expiry) {
            sessionStorage.removeItem(key);
            return null;
          }
          return item.value;
        };
        const userId = getSessionItemWithExpiry("userId");
        const response = await fetch(`${GET_USER}/${userId}`);
        const data = await response.json();
        if (response.ok) {
          setUserData(data.user);
          setFormData({
            fatherName: data.user.fatherName || "",
            motherName: data.user.motherName || "",
            phone: data.user.phone || "",
            date: data.user.date
              ? new Date(data.user.date).toISOString().substring(0, 10)
              : "",
            address: data.user.address || "",
            state: data.user.state || "",
            city: data.user.city || "",
            pincode: data.user.pincode || "",
            educationQualification: data.user.educationQualification || "",
          });
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const requiredFields = [
      "fatherName",
      "motherName",
      "phone",
      "date",
      "address",
      "state",
      "city",
      "pincode",
      "educationQualification",
    ];
  
    for (let field of requiredFields) {
      if (!String(formData[field] || "").trim()) {
        Swal.fire({
          icon: "warning",
          title: "Missing Field",
          text: `Please fill out the ${field} field.`,
          customClass: {
            container: "swal-container",
            popup: "swal-popup",
            title: "swal-title",
            content: "swal-content",
            confirmButton: "swal-confirm-button",
          },
          didOpen: () => {
            const swalContainer = document.querySelector(".swal-container");
            const swalPopup = document.querySelector(".swal-popup");
            const swalTitle = document.querySelector(".swal-title");
            const swalContent = document.querySelector(".swal-content");
            const swalConfirmButton = document.querySelector(".swal-confirm-button");
            if (swalContainer) {
              swalContainer.style.backdropFilter = "blur(5px)";
              swalContainer.style.fontFamily = "Arial, sans-serif";
            }
            if (swalPopup) {
              swalPopup.style.backgroundColor = "#f9fafb";
              swalPopup.style.border = "2px solid #e2e8f0";
              swalPopup.style.borderRadius = "10px";
              swalPopup.style.padding = "20px";
              swalPopup.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
            }
            if (swalTitle) {
              swalTitle.style.color = "#1d4ed8";
              swalTitle.style.fontSize = "1.5rem";
              swalTitle.style.fontWeight = "bold";
              swalTitle.style.textAlign = "center";
            }
            if (swalContent) {
              swalContent.style.color = "#475569";
            }
            if (swalConfirmButton) {
              swalConfirmButton.style.backgroundColor = "#1d4ed8";
              swalConfirmButton.style.color = "#fff";
              swalConfirmButton.style.borderRadius = "8px";
              swalConfirmButton.style.padding = "10px 20px";
              swalConfirmButton.style.fontWeight = "bold";
              swalConfirmButton.style.cursor = "pointer";
            }
          },
        });
        return;
      }
    }
    // const imageFile = document.querySelector('input[type="file"]').files[0];
    // if (!imageFile) {
    //   Swal.fire({
    //     icon: "warning",
    //     title: "Missing Profile Picture",
    //     text: "Please upload a profile picture before submitting.",
    //     customClass: {
    //       container: "swal-container",
    //       popup: "swal-popup",
    //       title: "swal-title",
    //       content: "swal-content",
    //       confirmButton: "swal-confirm-button",
    //     },
    //     didOpen: () => {
    //       const swalContainer = document.querySelector(".swal-container");
    //       const swalPopup = document.querySelector(".swal-popup");
    //       const swalTitle = document.querySelector(".swal-title");
    //       const swalContent = document.querySelector(".swal-content");
    //       const swalConfirmButton = document.querySelector(".swal-confirm-button");
    //       if (swalContainer) {
    //         swalContainer.style.backdropFilter = "blur(5px)";
    //         swalContainer.style.fontFamily = "Arial, sans-serif";
    //       }
    //       if (swalPopup) {
    //         swalPopup.style.backgroundColor = "#fffbf2";
    //         swalPopup.style.border = "2px solid #f1d100";
    //         swalPopup.style.borderRadius = "10px";
    //         swalPopup.style.padding = "20px";
    //         swalPopup.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
    //       }
    //       if (swalTitle) {
    //         swalTitle.style.color = "#eab308";
    //         swalTitle.style.fontSize = "1.5rem";
    //         swalTitle.style.fontWeight = "bold";
    //         swalTitle.style.textAlign = "center";
    //       }
    //       if (swalContent) {
    //         swalContent.style.color = "#1f2937";
    //         swalContent.style.fontSize = "1rem";
    //       }
    //       if (swalConfirmButton) {
    //         swalConfirmButton.style.backgroundColor = "#f1d100";
    //         swalConfirmButton.style.color = "#fff";
    //         swalConfirmButton.style.borderRadius = "8px";
    //         swalConfirmButton.style.padding = "10px 20px";
    //         swalConfirmButton.style.fontWeight = "bold";
    //         swalConfirmButton.style.cursor = "pointer";
    //         swalConfirmButton.style.border = "none";
    //         swalConfirmButton.style.transition = "background-color 0.3s";
    //         swalConfirmButton.addEventListener('mouseover', () => {
    //           swalConfirmButton.style.backgroundColor = "#e0a800";
    //         });
    //         swalConfirmButton.addEventListener('mouseout', () => {
    //           swalConfirmButton.style.backgroundColor = "#f1d100";
    //         });
    //       }
    //     },
    //   });
      
    //   return;
    // }
    
  
    try {
      const getSessionItemWithExpiry = (key)=> {
        const itemStr = sessionStorage.getItem(key)
;
        if (!itemStr) return null;
        const item = JSON.parse(itemStr);
        return item.value;
      };
      const userId = getSessionItemWithExpiry("userId");

      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      const imageFile = document.querySelector('input[type="file"]').files[0];
      if (imageFile) {
        formDataToSend.append("image", imageFile);
        console.log("Image File:", imageFile);
      }

      for (let pair of formDataToSend.entries()) {
        console.log(pair[0], pair[1]);
      }

      const response = await fetch(`${GET_USER}/${userId}`, {
        method: "PUT",
        body: formDataToSend,
      });

      const data = await response.json();
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Profile Updated Successfully!",
          text: "Your profile has been updated successfully.",
          customClass: {
            container: "swal-container",
            popup: "swal-popup",
            title: "swal-title",
            content: "swal-content",
            confirmButton: "swal-confirm-button",
          },
          didOpen: () => {
            const swalContainer = document.querySelector(".swal-container");
            const swalPopup = document.querySelector(".swal-popup");
            const swalTitle = document.querySelector(".swal-title");
            const swalContent = document.querySelector(".swal-content");
            const swalConfirmButton = document.querySelector(".swal-confirm-button");
        
            // Apply inline styles
            if (swalContainer) {
              swalContainer.style.backdropFilter = "blur(5px)";
              swalContainer.style.fontFamily = "Arial, sans-serif";
            }
            if (swalPopup) {
              swalPopup.style.backgroundColor = "#e0f7fa"; 
              swalPopup.style.border = "2px solid #00796b";
              swalPopup.style.borderRadius = "10px";
              swalPopup.style.padding = "20px";
              swalPopup.style.boxShadow = "0 4px 10px rgba(0, 0, 0, 0.1)";
            }
            if (swalTitle) {
              swalTitle.style.color = "#00796b"; 
              swalTitle.style.fontSize = "1.5rem";
              swalTitle.style.fontWeight = "bold";
              swalTitle.style.textAlign = "center";
            }
            if (swalContent) {
              swalContent.style.color = "#004d40"; 
              swalContent.style.fontSize = "1rem";
            }
            if (swalConfirmButton) {
              swalConfirmButton.style.backgroundColor = "#00796b";
              swalConfirmButton.style.color = "#fff";
              swalConfirmButton.style.borderRadius = "8px";
              swalConfirmButton.style.padding = "10px 20px";
              swalConfirmButton.style.fontWeight = "bold";
              swalConfirmButton.style.cursor = "pointer";
              swalConfirmButton.style.border = "none";
              swalConfirmButton.style.transition = "background-color 0.3s";
              swalConfirmButton.addEventListener('mouseover', () => {
                swalConfirmButton.style.backgroundColor = "#004d40"; 
              });
              swalConfirmButton.addEventListener('mouseout', () => {
                swalConfirmButton.style.backgroundColor = "#00796b";
              });
            }
          },
        });
        navigate("/content");
        setUserData(data.user);
      } else {
        console.error(data.message);
        alert(`Error: ${data.message}`);
      }
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Server error while updating user.");
    }
  };

  return (
    <>
      <div
        className="bg-[#1D57A5] h-[484px] text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Navbar />
      </div>
      <div className="flex items-center justify-center h-screen -mt-72">
        <div className="w-full max-w-4xl bg-white rounded-lg shadow-lg p-10">
          <div className="flex flex-col items-center">
          <div className="w-40 h-40 -mt-32 bg-gray-300 rounded-full flex justify-center items-center overflow-hidden relative">
      <img
        src={
          preview
            ? preview
            : userData?.image
            ? `${BASE_URL}${userData.image}`
            : "https://via.placeholder.com/100"
        }
        alt="Profile"
        className="rounded-full object-cover w-full h-full"
      />
      <label
        htmlFor="file-input"
        className="absolute inset-0 flex items-center justify-center cursor-pointer"
      >
        <span className="text-white rounded-full w-20 h-20 flex items-center justify-center text-lg">
          +
        </span>
      </label>
      <input
        id="file-input"
        type="file"
        className="hidden"
        onChange={handleFileChange}
      />
    </div>
           
            <h1 className="mt-4 text-xl font-semibold text-blue-600">
              {userData?.name || "User Name"}
            </h1>
            <p className="text-gray-500">{userData?.email || "User Email"}</p>
          </div>
          <h2 className="mt-6 text-lg font-medium text-center text-gray-800">
            Update your profile before appearing for Challenges.
          </h2>
          <form
            onSubmit={handleUpdate}
            className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2"
          >
            <input
              type="text"
              name="fatherName"
              placeholder="Father's Name"
              className="input-field rounded-lg py-1 px-4 border-2 border-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              value={formData.fatherName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="motherName"
              placeholder="Mother's Name"
              className="input-field rounded-lg py-1 px-4 border-2 border-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              value={formData.motherName}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="phone"
              placeholder="Mobile"
              className="input-field rounded-lg py-1 px-4 border-2 border-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              value={formData.phone}
              onChange={handleInputChange}
              pattern="^[0-9]{10}$"
              title="Phone number must be 10 digits."
            />
            <input
              type="date"
              name="date"
              placeholder="DOB"
              className="input-field rounded-lg py-1 px-4 border-2 border-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              value={formData.date}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="address"
              placeholder="Address"
              className="input-field rounded-lg py-1 px-4 border-2 border-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              value={formData.address}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="state"
              placeholder="State"
              className="input-field rounded-lg py-1 px-4 border-2 border-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              value={formData.state}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              className="input-field rounded-lg py-1 px-4 border-2 border-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              value={formData.city}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="pincode"
              placeholder="Pincode"
              className="input-field rounded-lg py-1 px-4 border-2 border-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              value={formData.pincode}
              onChange={handleInputChange}
              pattern="^[0-9]{6}$"
              title="Pincode must be 6 digits."
            />
            <input
              type="text"
              name="educationQualification"
              placeholder="Education Qualification"
              className="input-field rounded-lg py-1 px-4 border-2 border-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300"
              value={formData.educationQualification}
              onChange={handleInputChange}
            />

            <div className="col-span-2">
              <ul className="list-disc pl-5 space-y-2 text-gray-700">
                <li>Upload Profile Picture (Max 500kb)</li>
                <li>
                  Profile Picture and ID Proof once uploaded cannot be changed
                  in the future.
                </li>
                <li>
                  Upload Govt. Photo ID Proof (e.g., PAN Card, Passport, Voter
                  ID Card, Driving Licence, etc.) (Max 500kb)
                </li>
              </ul>
            </div>
            <div className="col-span-2 flex justify-center">
              <button
                type="submit"
                className="bg-blue-500 rounded-md px-4 py-2 text-white"
              >
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Profile;