import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const getSessionItemWithExpiry = (key) => {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      sessionStorage.removeItem(key);
      return null;
    }   
    return item.value;
  };

  const token = getSessionItemWithExpiry("token");

  return token ? <Component {...rest} /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
