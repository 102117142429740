import React from "react";
import Navbar from "../Navbar";
import Image from "../../image/MOTTO, SIGN, SALUTE AND LEFT HAND SHAKE.webp";
import Content from "../../image/C-10.jpg";
import Cont from "../../image/C-09.jpg";
import Conte from "../../image/C-11.jpg";

import backgroundImage from "../../image/newprofile.png";
import Pagination from "../Pagination";
const Content5 = () => {
  return (
<>
  <div
    className="h-[480px] text-white"
    style={{
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
    }}
  >
    <Navbar />
  </div>

  <div className="px-4 sm:px-8 md:px-16 lg:px-32 py-8">
    <div className="-mt-40 sm:-mt-72 md:-mt-96">

    <img src={Image} alt="Sign" className="w-full  mx-auto -mt-4 rounded-lg shadow-md" />
  
    </div>
    <div className="min-h-screen p-6">
      <header className="pt-4 mb-8">
    
      </header>

      {/* Motto Section */}
      <main className="mt-6 space-y-6 text-gray-800">
        <div className="bg-[#1D57A5] px-4 py-6 rounded-tl-3xl rounded-br-3xl">
          <h1 className="text-lg sm:text-xl px-5 text-white uppercase font-bold">
            MOTTO - OF DIFFERENT SECTIONS
          </h1>
        </div>
        <section>
          <p className="text-base font-normal mt-3 text-center">
            <strong>Bunny:</strong> Keep Smiling
          </p>
          <p className="text-base font-normal text-center">
            <strong>Cub / Bulbul:</strong> Do your Best
          </p>
          <p className="text-base font-normal text-center">
            <strong>Scout / Guide:</strong> Be Prepared
          </p>
          <p className="text-base font-normal text-center">
            <strong>Rover / Ranger:</strong> Service
          </p>
        </section>
      </main>
      <main className="mt-6 space-y-6 text-gray-800">
        <div className="bg-[#1D57A5] px-4 py-6 rounded-tl-3xl rounded-br-3xl">
          <h1 className="text-lg sm:text-xl px-5 text-white uppercase font-bold">
            SIGN
          </h1>
        </div>
        <section className="text-center">
          <img src={Content} alt="Sign" className="w-full  mx-auto mt-4 rounded-lg shadow-md" />
          <p className="text-base font-normal mt-4 px-4 sm:px-12 md:px-16">
            The sign identifies you as a Scout/ Guide anywhere in the world. The sign is given at the time of investiture and at the time of renewal of the Scout / Guide Promise. The Scout / Guide Sign is given by raising the right hand, in level with the shoulder, palm to front with three fingers stretched together and thumb closing on the little finger.
          </p>
        </section>
      </main>

      <main className="mt-6 space-y-6 text-gray-800">
        <div className="bg-[#1D57A5] px-4 py-6 rounded-tl-3xl rounded-br-3xl">
          <h1 className="text-lg sm:text-xl px-5 text-white uppercase font-bold">
            SALUTE
          </h1>
        </div>
        <section className="text-center">
          <img src={Cont} alt="Salute" className="w-full mx-auto mt-4 rounded-lg shadow-md" />
          <p className="text-base font-normal mt-4 px-4 sm:px-12 md:px-16">
            Salute is an expression of mutual esteem and good-will. It is a mark of respect and good manners. It is the privilege of the one who sees first to salute first. Salute is given by raising the right arm smartly to the level of the shoulder, palm to the front with the three fingers stretched together, the first touching the forehead one inch above the right eyebrow and the thumb closing on the little finger and after that the arm, cutting to the front is quickly and smartly brought down curling the fingers. Etiquette is to allow the other person to take the salute before bringing down the arm.
          </p>
        </section>
      </main>

    
      <main className="mt-6 space-y-6 text-gray-800">
        <div className="bg-[#1D57A5] px-4 py-6 rounded-tl-3xl rounded-br-3xl">
          <h1 className="text-lg sm:text-xl px-5 text-white uppercase font-bold">
            LEFT HAND SHAKE
          </h1>
        </div>
        <section className="text-center">
          <img src={Conte} alt="Left Hand Shake" className="w-full  mx-auto mt-4 rounded-lg shadow-md" />
          <p className="text-base font-normal mt-4 px-4 sm:px-12 md:px-16">
            Left Hand Shake greetings with a smile; shaking hands with the left and saluting with the right is a specialty of the movement. The Founder conceived the left-hand shake as a form of greeting the members of the movement. The idea originated from an incident in his military career in South Africa. When Col. Baden-Powell entered the capital city of the Ashanti tribe in 1896, he was received by one of the chiefs, who while greeting him formally extended his left hand. B.P. held out his right hand in return. But the chief said "No, in my country, the braves to the brave shake with left hand.”
          </p>
        </section>
      </main>
    </div>
    <Pagination />
  </div>
</>

  );
};

export default Content5;
