import React, { useState } from "react";
import Navbar from "./Navbar";
import backgroundImage from "../image/login.png";
import { Link, useNavigate } from "react-router-dom";
import image from "../image/shape.png";
import axios from "axios";
import Swal from "sweetalert2";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { POST_USER_REGISTER } from "../constant/constant";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    date: "",
    password: "",
    confirmPassword: "",
    gender: "",
  });

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !formData.name ||
      !formData.email ||
      !formData.phone ||
      !formData.date ||
      !formData.password ||
      !formData.confirmPassword ||
      !formData.gender
    ) {
      setError("All fields are required.");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please fill all  fields.",
      });
      return;
    }

 
    if (formData.password !== formData.confirmPassword) {
      setError("Passwords do not match.");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Passwords do not match.",
      });
      return;
    }

    const phonePattern = /^[0-9]{10}$/; 
    if (!phonePattern.test(formData.phone)) {
      setError("Please enter a valid 10-digit phone number.");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Phone number must be 10 digits.",
      });
      return;
    }

    if (formData.password.length < 8) {
      setError("Password must be at least 8 characters long.");
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Password must be at least 8 characters long.",
      });
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        POST_USER_REGISTER,
        formData
      );
      setSuccess(response.data.message);
      setError("");

      Swal.fire({
        icon: "success",
        title: "Registration Successful",
        text: response.data.message,
      });
      setFormData({
        name: "",
        email: "",
        phone: "",
        date: "",
        password: "",
        confirmPassword: "",
        gender: "",
      });
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    } catch (error) {
      setError(error.response ? error.response.data.message : "Server error.");

      Swal.fire({
        icon: "error",
        title: "Registration Failed",
        text: error.response ? error.response.data.message : "Server error.",
      });
    }finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative h-screen">
      {loading && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      )}
      <div
        className="absolute inset-0 "
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>

      <div className="absolute top-0 left-0 right-0 items-center mb-20">
        <Navbar />
        <div className="relative flex justify-between items-center h-full px-10 mt-12 mb-20">
          <div className="text-white max-w-lg">
            <h1 className="text-5xl font-bold mb-4">Introduction</h1>
            <h1 className="text-2xl font-bold">Welcome to Online Beginners Course  of <br /> the Bharat Scouts
            and Guides.</h1>
          </div>
          <div
            className="flex justify-center items-center mb-20 "
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "590px",
              height: "500px",
            }}
          >
            <div className="bg-white shadow-lg rounded-lg p-6 w-auto mb-20">
              <h2 className="text-2xl font-bold">Signup</h2>
              <p className="text-black font-semibold">for a free BSG account</p>

              <form onSubmit={handleSubmit}>
                <div className="mt-10 flex gap-4 ">
                  <div className="flex-1">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter your name"
                    />
                  </div>
                  <div className="flex-1">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter your email"
                    />
                  </div>
                </div>

                <div className=" mt-2 flex gap-4">
                  <div className="flex-1">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      maxLength={10}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Enter your phone number"
                    />
                  </div>
                  <div className="flex-1">
                    <label
                      htmlFor="date"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Date of Birth
                    </label>
                    <input
                      type="date"
                      id="date"
                      name="date"
                      value={formData.date}
                      onChange={handleChange}
                      className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div className=" mt-2 flex gap-4">
                  <div className="flex-1">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Password
                    </label>
                    <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Enter your password"
                      />
                      <span
                        onClick={handlePasswordVisibility}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      >
                        {showPassword ? (
                          <AiOutlineEyeInvisible />
                        ) : (
                          <AiOutlineEye />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex-1">
                    <label
                      htmlFor="confirm-password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Confirm Password
                    </label>
                    <div className="relative">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirm-password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Confirm your password"
                      />
                      <span
                        onClick={handleConfirmPasswordVisibility}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      >
                        {showConfirmPassword ? (
                          <AiOutlineEyeInvisible />
                        ) : (
                          <AiOutlineEye />
                        )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className=" mt-2 flex-1">
                  <label
                    htmlFor="gender"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Gender
                  </label>
                  <select
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="" disabled>
                      Select your gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <button
                  type="submit"
                  className="mt-6 w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600"
                >
                  Signup
                </button>
              </form>

              <div className="mt-4 text-sm">
                <p>
                  Already have an account?{" "}
                  <Link to="/login" className="text-blue-500 hover:underline">
                    Login here
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
