import React from "react";
import Slider from "react-slick";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slider1 from "../image/slidernew1.webp";
import slider2 from "../image/slidernew2.webp";

import Navbar from "./Navbar";

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    prevArrow: (
      <div className="slick-prev text-3xl absolute left-4 top-4 text-black ">
        <FaArrowLeft />
      </div>
    ),
    nextArrow: (
      <div className="slick-next text-3xl absolute right-4 top-4 text-white z-10">
        <FaArrowRight />
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true, 
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true, 
          autoplay: true,
        },
      },
    ],
  };

  return (
    <div className="relative overflow-hidden">
      <div className="absolute left-0 right-0 z-10 text-white">
        <Navbar />
      </div>

      <Slider {...settings}>
        <div>
          <img src={slider1} alt="Slide 1" className="w-full h-auto" />
        </div>
        <div>
          <img src={slider2} alt="Slide 2" className="w-full h-auto" />
        </div>
      
      </Slider>
    </div>
  );
};

export default Carousel;
