import React from "react";
import image1 from "../image/1mobile.png";
import image2 from "../image/2mobile.png";
import image3 from "../image/3mobile.png";

const BeginnerCourse = () => {
  return (
    <>
      <h1 className="text-blue-800 text-center font-semibold text-4xl mt-5 p-4 font-Calibribold">
        ONLINE BEGINNERS COURSE
      </h1>
      {/* <div className="flex flex-wrap p-6 justify-center gap-4 sm:gap-6 md:justify-around">
        <img src={image1} alt="Course Image 1" className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 object-contain" />
        <img src={image2} alt="Course Image 2" className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 object-contain" />
        <img src={image3} alt="Course Image 3" className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 object-contain" />
      </div> */}
    </>
  );
};

export default BeginnerCourse;
