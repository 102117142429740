// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Home from "./component/Home";
// import LoginPage from "./component/Login";
// import Footer from "./component/Footer";
// import Register from "./component/Register";
// import LearningContent from "./component/LearningContent";
// import Content1 from "./component/Content/Content1";
// import Content2 from "./component/Content/Content2";
// import Content3 from "./component/Content/Content3";
// import Content4 from "./component/Content/Content4";
// import Content5 from "./component/Content/Content5";
// import Content6 from "./component/Content/Content6";
// import Content7 from "./component/Content/Content7";
// import Content8 from "./component/Content/Content8";
// import Challenge from "./component/Challenge";
// import ChallengePage from "./component/ChallengePage";
// import Profile from "./component/Profile";
// import Certificates from "./component/Certificates";
// import ProtectedRoute from "./component/ProtectedRoute.jsx";
// const App = () => {
//   return (
//     <div className="flex flex-col min-h-screen">
//       <Router>
//         <div className="flex-grow">
//           <Routes>
//             <Route path="/" element={<Home />} />
//             <Route path="/login" element={<LoginPage />} />
//             <Route path="/register" element={<Register />} />
//             <Route path="/content" element={<LearningContent />} />
//             <Route
//               path="/challenges"
//               element={<ProtectedRoute element={Challenge} />}
//             />
//             <Route
//               path="/profile"
//               element={<ProtectedRoute element={Profile} />}
//             />
//             <Route
//               path="/challenges/:chapter"
//               element={<ProtectedRoute element={ChallengePage} />}
//             />
//             <Route path="/content/1" element={<Content1 />} />
//             <Route path="/content/2" element={<Content2 />} />
//             <Route path="/content/3" element={<Content3 />} />
//             <Route path="/content/4" element={<Content4 />} />
//             <Route path="/content/5" element={<Content5 />} />
//             <Route path="/content/6" element={<Content6 />} />
//             <Route path="/content/7" element={<Content7 />} />
//             <Route path="/content/8" element={<Content8 />} />
//             <Route
//               path="/certificates"
//               element={<ProtectedRoute element={Certificates} />}
//             />
//           </Routes>
//         </div>
//         <Footer />
//       </Router>
//     </div>
//   );
// };

// export default App;


import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./component/Home";
import LoginPage from "./component/Login";
import Footer from "./component/Footer";
import Register from "./component/Register";
import LearningContent from "./component/LearningContent";
import Content1 from "./component/Content/Content1";
import Content2 from "./component/Content/Content2";
import Content3 from "./component/Content/Content3";
import Content4 from "./component/Content/Content4";
import Content5 from "./component/Content/Content5";
import Content6 from "./component/Content/Content6";
import Content7 from "./component/Content/Content7";
import Content8 from "./component/Content/Content8";
import Challenge from "./component/Challenge";
import ChallengePage from "./component/ChallengePage";
import Profile from "./component/Profile";
import Certificates from "./component/Certificates";
import ProtectedRoute from "./component/ProtectedRoute.jsx";
import ForgotPasswordPage from "./component/ForgotPassword.jsx";
import Guidelines from "./component/GuidelinesPage.jsx";

const App = () => {
  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  return (
    <div
      className="flex flex-col min-h-screen"
      onContextMenu={handleContextMenu}
    >
      <Router>
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage/>} />
            <Route path="/content" element={<LearningContent />} />
            <Route
              path="/challenges"
              element={<ProtectedRoute element={Challenge} />}
            />
            <Route
              path="/profile"
              element={<ProtectedRoute element={Profile} />}
            />
            <Route
              path="/challenges/:chapter"
              element={<ProtectedRoute element={ChallengePage} />}
            />
            <Route path="/content/1" element={<Content1 />} />
            <Route path="/content/2" element={<Content2 />} />
            <Route path="/content/3" element={<Content3 />} />
            <Route path="/content/4" element={<Content4 />} />
            <Route path="/content/5" element={<Content5 />} />
            <Route path="/content/6" element={<Content6 />} />
            <Route path="/content/7" element={<Content7 />} />
            <Route path="/content/8" element={<Content8 />} />
            <Route path="/guideline" element={<Guidelines/>} />
            <Route
              path="/certificates"
              element={<ProtectedRoute element={Certificates} />}
            />
          </Routes>
        </div>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
