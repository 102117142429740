import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { BASE_URL, POST_CERTIFICATE } from "../constant/constant";
const Certificates = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [certificateUrl, setCertificateUrl] = useState("");

  const getSessionItemWithExpiry = (key) => {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) return null;
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      sessionStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  const userId = getSessionItemWithExpiry("userId");

  const checkAndGenerateCertificate = async () => {
    if (!userId) {
      setMessage("User ID not found. Please log in again.");
      return;
    }
    try {
      setLoading(true);
      const response = await fetch(`${POST_CERTIFICATE}/${userId}`, {
        method: "POST",
      });
      const data = await response.json();
      if (response.status === 200 || response.status === 400) {
        setCertificateUrl(data.certificateUrl);
        setMessage(data.message);
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      console.error("Error generating certificate:", error);
      setMessage("Internal server error. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userId) {
      checkAndGenerateCertificate();
    } else {
      setMessage("No user found. Please log in.");
    }
  }, [userId]);

  return (
    <div>
      <div className="bg-[#1D57A5] h-64 text-white">
        <Navbar />
        <h1 className="font-semibold text-2xl md:text-3xl mt-3 text-center text-white">
          BEGINNERS COURSE
        </h1>
        <h1 className="font-medium text-base md:text-xl text-center mt-3 px-4 md:px-0 text-white">
          Once you start any one of the Challenges, you have to complete the
          rest of the challenges within 15 days.
        </h1>
      </div>
      <div className="text-center mt-8">
        {loading && <p>Loading...</p>}
        {message && !loading && (
          <div>
            <p className="mt-4 font-semibold text-2xl">{message}</p>
            {certificateUrl && (
              <div>
                <a
                  href={`${BASE_URL}/${certificateUrl}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="bg-blue-500 text-white px-4 py-2  text-lg rounded mt-5 mb-4">
                    View Certificate
                  </button>
                </a>
                <p className="mt-4 font-semibold text-red-400 text-sm mb-5">
                  Note: Now contact with your District Headquarters, where group
                  registration form and warrant will be available Rs. 100 /- to
                  be given to District Headquarters for record in DHQ.
                </p>
              </div>
            )}
            {/* <div className="mt-4 bg-white overflow-hidden mb-5">
              {certificateUrl ? (
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  <Viewer fileUrl={`${BASE_URL}/${certificateUrl}`} />
                </Worker>
              ) : (
                <p className="text-gray-500">No certificate available.</p>
              )}
            </div> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default Certificates;
