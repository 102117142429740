import React from "react";
import sampleImage from "../image/Guidelines.png";
import backgroundImage from "../image/background.jpg"
import Navbar from "./Navbar";

function Guidelines() {
  return (
    <>
        <div className="absolute left-0 right-0 z-10 text-white">
        <Navbar />
      </div>
    <div 
      className="bg-blue-900 text-white p-8 md:p-16"
      style={{ 
        backgroundImage: `url(${backgroundImage})`, 
        backgroundSize: 'cover', 
        backgroundRepeat: 'no-repeat', 
        backgroundPosition: 'center' 
      }}>

      <div className="grid md:grid-cols-2 gap-8">
        <div>
          <h1 className="text-yellow-400 text-2xl sm:text-3xl md:text-4xl font-bold mb-4 mt-10">
            GUIDELINES
          </h1>
          <div className="border-t-4 border-white w-24 sm:w-32 mb-4"></div>
          <p className="mb-6">
            This portal is created to provide knowledge of Scouting & Guiding
            not to evaluate the capacity of any individual. We have made this
            portal such a way that anyone can enjoy the essence of Scouting &
            Guiding virtually.
          </p>
          <ul className="space-y-4">
            {[
              "For Login, create Id and password.",
              "Provide Name in Capital Letter and the same will appear in Your certificate.",
              "OTP will be sent to your Email Id at the time of create/forget/reset account.",
              "Before starting the challenges, complete your profile.",
              "Profile Picture and ID-Proof once uploaded cannot be changed.",
              "Size of Profile Picture and ID-Proof not more than 500kb each.",
              "Any time anyone can explore the Learning Content.",
              "Learning content consist with eight subjects.",
              "You can read all the learning contents at a time.",
              "But once you start to attempt any of the challenges (Q & A), the entire challenges must be completed within 15 days otherwise you have to start from the beginning.",
              "Each subject having 10 multiple choice questions.",
              "Any time you can explore the learning content by saving your answer but once logout, all saved data will be deleted.",
              "On successful completion of each subject with 100% marks, you will be eligible to download Certificate after completing 3 mandatory steps by listening BSG Prayer, Flag Song and taking of BSG Promise.",
              "This certificate is valid only with a Govt. issued Photo Identity Card.",
              "After received your certificate contact with your District Headquarters, where group registration form and warrant will be available Rs. 100 /- to be given to District Headquarters for record in DHQ."
            ].map((text, index) => (
              <li key={index} className="flex items-start">
                <div className="bg-yellow-400 text-blue-900 rounded-full w-8 h-8 flex items-center justify-center font-bold mr-4">
                  {String(index + 1).padStart(2, "0")}
                </div>
                <p className="flex-1">{text}</p>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-center items-center">
          <img
            src={sampleImage}
            alt="Online Course"
            className="w-auto"
          />
        </div>
      </div>
    </div>
    </>
  );
}

export default Guidelines;
