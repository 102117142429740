// export const BASE_URL="http://localhost:5000"
export const BASE_URL="https://bw-newobc.bsgindia.tech"
export const POST_USER_REGISTER=BASE_URL+"/api/v1/register"
export const POST_USER_lOGIN=BASE_URL+"/api/v1/login"
export const POST_FORGETPASSWORD=BASE_URL+"/api/v1/forget-password"
export const GET_CHALLENGE=BASE_URL+"/api/v1/chapters/all"
export const GET_CHAPTERNAME=BASE_URL+"/api/v1"
export const POST_SUBMIT_ANSWER=BASE_URL+"/api/v1/submit-answers"
export const POST_ADDRESS=BASE_URL+"/api/v1/addresses"
export const POST_LOGOUT=BASE_URL+"/api/v1/logout"
export const POST_CERTIFICATE=BASE_URL+"/api/v1/generate-certificate"
export const GET_USER=BASE_URL+"/api/v1/user"