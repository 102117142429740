import React from "react";
import Navbar from "../Navbar";
import Image from "../../image/THE PROMISE AND LAW.webp";

import backgroundImage from "../../image/newprofile.png";
import Pagination from "../Pagination";

const Content4 = () => {
  const videoUrl = "https://www.youtube.com/embed/5Oyjsb3LFZc";
  const videoUrl2 = "https://www.youtube.com/embed/f00ZsU1fd_E";

  return (
    <>
      {/* Background and Navbar */}
      <div
        className="h-[480px] text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Navbar />
      </div>

      {/* Content Section */}
      <div className="px-6 sm:px-12 lg:px-32 py-20">
        <div className="-mt-96">
          <div className="max-w-full justify-center items-center rounded-full mx-auto">
            <img className="w-full" src={Image} alt="LC4" />
          </div>
        </div>

        {/* Main Content */}
        <div className="min-h-screen p-6">
          <main className="mt-6 space-y-6 text-gray-800">
            <div className="bg-[#0057A8] px-2 py-4 rounded-tl-3xl rounded-br-3xl">
              <h1 className="text-lg px-5 text-white uppercase mt-2 text-start font-bold">
                THE PROMISE
              </h1>
            </div>
            {["Cub", "Bulbul"].map((section, index) => (
              <section key={index}>
                <h2 className="text-2xl font-bold ">{`${section} Promise:`}</h2>
                <p className="text-base font-normal">I promise to do my best</p>
                <p className="text-base font-normal">
                  To do my duty to God* and my country
                </p>
                <p className="text-base font-normal">
                  To keep the {section} Law and
                </p>
                <p className="text-base font-normal">
                  To do Good turn every day.
                </p>
              </section>
            ))}
            <section>
              <h2 className="text-2xl font-bold ">
                Scout/Rover Promise:
              </h2>
              <p className="text-base font-normal">
                On my honour, I promise that,
              </p>
              <p className="text-base font-normal">
                I will do my best To do my duty to God* and my country,
              </p>
              <p className="text-base font-normal">To help other people and</p>
              <p className="text-base font-normal">To obey the Scout Law.</p>
            </section>
            <section>
              <h2 className="text-2xl font-bold ">
                Guide/Ranger Promise:
              </h2>
              <p className="text-base font-normal">
                On my honour, I promise that,
              </p>
              <p className="text-base font-normal">
                I will do my best To do my duty to God* and my country,
              </p>
              <p className="text-base font-normal">To help other people and</p>
              <p className="text-base font-normal">To obey the Guide Law.</p>
            </section>

            <p className="text-base font-normal">
              <b>Note:</b> The word 'Dharma' may be substituted for the word
              'God' if so desired.
            </p>
          </main>
          <main className="mt-6 space-y-6 text-gray-800">
            <div className="bg-[#0057A8] px-2 py-4 rounded-tl-3xl rounded-br-3xl">
              <h1 className="text-lg px-5 text-white uppercase mt-2 text-start font-bold">
                THE LAW
              </h1>
            </div>
            <section>
              <h2 className="text-xl font-bold -mt-2">
                CUB SECTION - TWO PARTS OF LAW
              </h2>
              <ul className="list-decimal list-inside space-y-2 mt-2">
                {["The Cub is obedient.", "The Cub is clean and polite."].map(
                  (law, index) => (
                    <li key={index} className="text-lg">
                      {law}
                    </li>
                  )
                )}
              </ul>
            </section>
            <section>
              <h2 className="text-xl font-bold -mt-2">
               BULBUL SECTION - TWO PARTS OF LAW
              </h2>
              <ul className="list-decimal list-inside space-y-2 mt-2">
                {[
                  "The Bulbul is obedient.",
                  "The Bulbul is clean and polite.",
                ].map((law, index) => (
                  <li key={index} className="text-lg">
                    {law}
                  </li>
                ))}
              </ul>
            </section>
            <section>
              <h2 className="text-xl font-bold -mt-5">
                SCOUT/ROVER SECTION - NINE PARTS OF LAW
              </h2>
              <ul className="list-decimal list-inside space-y-2 mt-1">
                {[
                  "A Scout is trustworthy.",
                  "A Scout is Loyal.",
                  "A Scout is a friend to all and a brother to every other Scout.",
                  "A Scout is courteous.",
                  "A Scout is a friend to animals and loves nature.",
                  "A Scout is disciplined and helps protect public property.",
                  "A Scout is courageous.",
                  "A Scout is thrifty.",
                  "A Scout is pure in thought, word and deed.",
                ].map((law, index) => (
                  <li key={index} className="text-lg">
                    {law}
                  </li>
                ))}
              </ul>
            </section>
            <section>
              <h2 className="text-xl font-bold -mt-2">
                GUIDE/RANGER SECTION - NINE PARTS OF LAW
              </h2>
              <ul className="list-decimal list-inside space-y-2 mt-2">
                {[
                  "A Guide is trustworthy.",
                  "A Guide is Loyal.",
                  "A Guide is a friend to all and a sister to every other Guide.",
                  "A Guide is courteous.",
                  "A Guide is a friend to animals and loves nature.",
                  "A Guide is disciplined and helps protect public property.",
                  "A Guide is courageous.",
                  "A Guide is thrifty.",
                  "A Guide is pure in thought, word and deed.",
                ].map((law, index) => (
                  <li key={index} className="text-lg">
                    {law}
                  </li>
                ))}
              </ul>
            </section>

            {/* Cub Law */}
          </main>
        </div>

        <div className="max-w-5xl mx-auto pt-3 px-4 flex flex-col sm:flex-row md:flex-row justify-center sm:space-x-8 md:space-x-8 space-y-8 md:space-y-0 items-center">
          <div
            className="relative w-full sm:w-1/2 md:w-1/2"
            style={{ paddingTop: "56.25%" }}
          >
            <iframe
              src={videoUrl}
              title="YouTube video player 1"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 w-full h-64 rounded-lg shadow-lg transition-transform hover:scale-105"
            ></iframe>
          </div>

          <div
            className="relative w-full sm:w-1/2 md:w-1/2"
            style={{ paddingTop: "56.25%" }}
          >
            <iframe
              src={videoUrl2}
              title="YouTube video player 2"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 w-full h-64 rounded-lg shadow-lg transition-transform hover:scale-105"
            ></iframe>
          </div>
        </div>

        {/* Pagination */}
        <Pagination />
      </div>
    </>
  );
};

export default Content4;
