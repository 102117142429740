import React from 'react'
import Carousel from './Carousel'
import BeginnerCourse from './BeginnerCourse'
import Intro1 from './Intro1'
import CourseOverview from './CourseOverview '
import Footer from './Footer'
import Guidelines from './Guidelines'

const Home = () => {
  return (
    <div>
        <Carousel/>
        <BeginnerCourse/>
        <Intro1/>
        <CourseOverview/>
        <Guidelines/>
        
    </div>
  )
}

export default Home