import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const Pagination = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 8;
  const navigate = useNavigate();
  const { page } = useParams(); 

 
  useEffect(() => {
    const pageNumber = parseInt(page) || 1;
    setCurrentPage(pageNumber);
  }, [page]);

  const handlePageChange = (page) => {
    setCurrentPage(page); 
    navigate(`/content/${page}`); 
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="flex justify-center py-6">
      <nav aria-label="Pagination Navigation">
        <ul className="flex space-x-2">
          {[...Array(totalPages)].map((_, index) => {
            const page = index + 1;
            return (
              <li key={page}>
                <button
                  onClick={() => handlePageChange(page)}
                  className='flex items-center justify-center w-10 h-10 text-sm font-medium rounded-full transition-colors duration-200'
                   
                >
                  {page}
                </button>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
