import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import Navbar from "./Navbar";
import { GET_CHAPTERNAME, POST_SUBMIT_ANSWER } from "../constant/constant";

const ChallengePage = () => {
  const { chapter } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [loading, setLoading] = useState(true);
  const [apiloading, setapiloading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${GET_CHAPTERNAME}/chapters/questions?chapterName=${chapter}`
        );
        setQuestions(response.data.data);
        setLoading(false);
      } catch (err) {
        console.error("Failed to fetch questions");
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [chapter]);

  const handleAnswerChange = (questionIndex, selectedValue) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionIndex]: selectedValue,
    });
  };
  const handleSubmit = async () => {
    try {
      const answers = questions.map((question, index) => ({
        questionId: question._id,
        options: selectedAnswers[index],
      }));

      const getSessionItemWithExpiry = (key) => {
        const itemStr = sessionStorage.getItem(key)
;
        if (!itemStr) return null;

        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() > item.expiry) {
          sessionStorage.removeItem(key)
;
          return null;
        }

        return item.value;
      };
      const userId = getSessionItemWithExpiry("userId");
      setapiloading(true);
      const response = await axios.post(POST_SUBMIT_ANSWER, {
        userId,
        chapterName: chapter,
        answers,
      });
      Swal.fire({
        title: "Success!",
        text: response.data.message,
        icon: "success",
        confirmButtonText: "OK",
      });
      setTimeout(() => {
        navigate(`/challenges`);
      }, 2000);
    } catch (err) {
      console.error("Failed to submit answers");
      setTimeout(() => {
        navigate(`/challenges`);
      }, 2000);
    }finally {
      setapiloading(false);
    }
  };
  if (loading) {
    return <div>Loading questions...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div className="bg-[#1D57A5] h-64 text-white">
      {apiloading && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      )}
        <Navbar />
        <h1 className="font-semibold text-4xl mt-3 text-center text-white">
          CHALLENGE
        </h1>
        <h1 className="font-semibold text-2xl text-center mt-3 text-white">
          Complete The Challenge
        </h1>
      </div>
      <div className="p-6 bg-gray-100 min-h-screen">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {questions.map((questionData, index) => (
            <div
              key={questionData._id}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              <div className="bg-[#1D57A5] text-white p-12">
                <h3 className="text-lg font-semibold">
                  Q{index + 1}. {questionData.question}
                </h3>
              </div>

              <div className="p-4">
                <ul className="space-y-4">
                  {questionData.options.map((option, i) => (
                    <li key={i} className="flex items-center">
                      <input
                        type="radio"
                        id={`question-${index}-option-${i}`}
                        name={`question-${index}`}
                        className="w-5 h-5 text-blue-600 focus:ring-blue-500 border-gray-300"
                        checked={selectedAnswers[index] === option} // Compare value, not index
                        onChange={() => handleAnswerChange(index, option)} // Send the value
                      />
                      <label
                        htmlFor={`question-${index}-option-${i}`}
                        className="ml-3 text-gray-700 font-medium"
                      >
                        {option}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        
        <div className="text-center mt-10">
          <button
            onClick={handleSubmit}
            type="button"
            className="bg-[#1D57A5] text-white py-3 px-6 rounded-lg text-lg font-semibold hover:bg-blue-700 transition"
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

export default ChallengePage;