import React from "react";
import Navbar from "../Navbar";
import Image from "../../image/HOW TO START AND REGISTER UNIT & CONDUCT OF UNIT MEETINGS.webp";
import image from "../../image/bulbuls.png";
import imag from "../../image/guides.png";
import images from "../../image/rangers.png";
import imaged from "../../image/Screenshot_8.jpg"
import imageds from "../../image/scouts.png"
import imagees from "../../image/rovers.png"
import backgroundImage from "../../image/newprofile.png";
import Pagination from "../Pagination";

const handleClick = () => {
  window.open(image, "_blank");
};
const handleClic = () => {
  window.open(imag, "_blank");
};
const handleClicks = () => {
  window.open(images, "_blank");
};
const handleClicked = () => {
  window.open(imaged, "_blank");
};
const handleClickeds = () => {
  window.open(imageds, "_blank");
};
const handleClicke = () => {
  window.open(imagees, "_blank");
};

const Content7 = () => {
  return (
    <>
      <div
        className=" h-[480px] text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Navbar />
      </div>

      <div className="px-32 py-20">
        <div className="lg:-mt-96 -mt-60">
          <div className="w-full justify-center items-center">
            <img className="w-full" src={Image} />
          </div>
        </div>
        <div className=" min-h-screen p-6">
          <header className=" pt-2 ">
           
          </header>

          <main className="mt-6 space-y-6 text-gray-800">
            <div className="bg-[#1D57A5] px-2 py-4 rounded-tl-3xl  rounded-br-3xl">
              <h1 className="text-lg  px-5 text-white uppercase mt-2 text-start font-bold">
                HOW TO START A UNIT
              </h1>
            </div>
            <section>
              <h2 className="text-2xl font-bold  -mt-5">
                How to Start:
              </h2>
              <p className="text-base font-normal">
                After completion of Beginners Course, it is time to start and
                run, also register a Unit with Local Association / District
                Association as the case may be. In order to start the unit, a
                request may be made to the Head of the Institution giving
                details of Scouting / Guiding and invite the candidates who are
                willing to join Scouting / Guiding.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-bold  -mt-5">
                Notice Board:
              </h2>
              <p className="text-base font-normal">
                A notice is to be placed on the notice board of the institution
                with Unit Leaders name, age, address and mobile number. In this
                regard, the students who are interested may be asked for
                permission from the Parents. In the notice, particular date and
                time is to be mentioned forassembly.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-bold  -mt-5">
                Motivation Talk:
              </h2>
              <p className="text-base font-normal">
                Unit Leader may also be asked to give a motivational talk in the
                respective classes of school. He may also add some of the
                stories from Scouting for Boys.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-bold  -mt-5">
                Equipment Shop:
              </h2>
              <p className="text-base font-normal">
                The Unit Leader who has already undergone the Beginners course
                may enquire, from the equipment shop at the District level for
                the purchase of flag and other materials and literature. He /
                She is also to ensure that from where the material of uniform
                and badges can be purchased. Also know the correct wayof
                stitching of Uniform so that he/she may guide the concerned
                tailor in order to get the uniform stitched for theirunit.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-bold  -mt-5"> 
                Unit Records:
              </h2>
              <p className="text-base font-normal">
                A Unit Leader should also maintain a register containing all the
                details of Unit Members like name, address, date of birth,
                mobile number, parents mobile number, date of joining etc.
                Accordingly he / she has to form Patrols / Six.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-bold  -mt-5">
                Application Form:
              </h2>
              <p className="text-base font-normal">
                A specimen form is enclosed herewith in order to get the unit
                registered with Local / District Association and necessary
                charges may be paid to the Association and get the Warrant which
                is a Authority to run the Unit under the banner ofconcerned
                StateAssociation.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-bold  -mt-5">
                Pravesh Requirements:
              </h2>
              <p className="text-base font-normal">
                Unit Leader should ensure that the requirements for Pravesh
                badge should be completed by the Unit members before awarding
                them the Pravesh Badge. It is also ensured that unit meeting is
                to be conducted periodically and the method of teaching will be
                on the basis of their respective section. In the beginning
                month, Unit Leader has to motivate them in such a way that the
                members of the unit should be anxious to attend the next
                meeting. Gradually the Unit Leaders should go through the
                activities according to the APRO. It is advisable that at the
                time of investiture, if the parents are invited, the ceremony
                will be full of sanctity.
              </p>
            </section>
            <section>
              <h2 className="text-2xl font-bold  -mt-5">FEE:</h2>
              <p className="text-base font-normal">
                Every group has to pay Affiliation Fee (for unit) and Individual
                Registration Fee (IRF) to District Association as may be fixed
                by the State Association.
              </p>
            </section>
          </main>
          <main className="mt-6 space-y-6 text-gray-800">
            <div className="bg-[#1D57A5]  px-2 py-4 rounded-tl-3xl  rounded-br-3xl">
              <h1 className="text-lg  px-5 text-white uppercase  text-start font-bold">
                HOW TO START A UNIT
              </h1>
            </div>
          </main>
          <div>
            <p
              className="text-base font-bold text-blue-600 mt-4 cursor-pointer"
              onClick={handleClick}
            >
              MODEL FLOCK MEETING (60 MTS) FOR BULBULS
            </p>
          </div>
          <p
            className="text-base font-bold text-blue-600 cursor-pointer"
            onClick={handleClic}
          >
            MODEL COMPANY MEETING (75 MTS) FOR GUIDES
          </p>
          <p
            className="text-base font-bold text-blue-600 cursor-pointer"
            onClick={handleClicks}
          >
            MODEL TEAM MEETING (105 MTS) FOR RANGERS
          </p>
          <p className="text-base font-bold text-blue-600 cursor-pointer"
             onClick={handleClicked}
             >
            MODEL PACK MEETING (90 MTS) FOR CUBS
          </p>
          <p className="text-base font-bold text-blue-600 cursor-pointer"
             onClick={handleClickeds}
             >
            MODEL TROOP MEETING (75 MTS) FOR SCOUTS
          </p>
          <p className="text-base font-bold text-blue-600 cursor-pointer"
            onClick={handleClicke}
          >
            MODEL CREW MEETING (105 MTS) FOR ROVERS
          </p>
         
        </div>
        <Pagination/>
      </div>
    </>
  );
};

export default Content7;
