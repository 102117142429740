import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";
import logo from "../image/bsg_logo.png";
import axios from "axios";
import Swal from "sweetalert2";
import { POST_LOGOUT } from "../constant/constant";
const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const getSessionItemWithExpiry = (key) => {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) return null;
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
      sessionStorage.removeItem(key);
      return null;
    }
    return item.value;
  };

  const token = getSessionItemWithExpiry("token");

  const handleLogout = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, logout",
    });

    if (result.isConfirmed) {
      try {
        await axios.post(
          POST_LOGOUT,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        sessionStorage.clear();
        Swal.fire({
          title: "Logged out",
          text: "You have successfully logged out!",
          icon: "success",
        }).then(() => {
          window.location.href = "/";
        });
      } catch (error) {
        console.error("Error logging out:", error);
        Swal.fire({
          title: "Error",
          text: "Failed to log out. Please try again.",
          icon: "error",
        });
      }
    }
  };

  return (
    <>
      {/* Desktop and Tablet Navbar */}
      <div className="flex items-center justify-between p-4 ">
        <Link to="/" className="transition duration-300">
          <img
            src={logo}
            alt="Logo"
            className="lg:w-80 w-20 md:w-40 sm:w-32 border-[1.2px] border-white"
          />
        </Link>
        <button
          onClick={toggleMenu}
          className="text-2xl text-gray-700 sm:hidden focus:outline-none"
        >
          {isMenuOpen ? <FiX className="text-white" /> : <FiMenu className="text-white"/>}
        </button>
        <nav className="hidden sm:flex space-x-4 text-base sm:text-base md:text-lg items-center">
          <Link
            to="/"
            className="flex text-base items-center gap-2 transition duration-300"
          >
            Home
          </Link>
          <Link
            to="/content"
            className="flex text-base items-center gap-2 transition duration-300"
          >
            Let`s Begin
          </Link>
          <Link
            to="/guideline"
            className="flex text-base items-center gap-2 transition duration-300"
          >
            Guideline
          </Link>
          {token ? (
            <>
              <Link
                to="/challenges"
                className="flex text-base items-center gap-2 transition duration-300 "
              >
              Test
              </Link>
              <Link
                to="/certificates"
                className="flex text-base items-center gap-2 transition duration-300 "
              >
                Certificates
              </Link>
              <Link
                to="/profile"
                className="flex text-base items-center gap-2 transition duration-300 "
              >
                Profile
              </Link>
              <button
                onClick={handleLogout}
                className="flex items-center text-base gap-2 transition duration-300 text-red-300 "
              >
                Logout
              </button>
            </>
          ) : (
            <>
              <Link
                to="/login"
                className="flex text-base items-center gap-2 transition duration-300 "
              >
                Login
              </Link>
              <Link
                to="/register"
                className="flex text-base items-center gap-2 transition duration-300  "
              >
                Register
              </Link>
            </>
          )}
        </nav>
      </div>

      {/* Mobile Menu (Visible when isMenuOpen is true) */}
      {isMenuOpen && (
        <nav className="sm:hidden bg-white shadow-md">
          <ul className="flex flex-col items-start">
            <Link
              to="/"
              className="flex items-center gap-2 w-full text-gray-800 hover:text-blue-500"
              onClick={toggleMenu}
            >
              Home
            </Link>
            <Link
              to="/content"
              className="flex items-center gap-2 w-full text-gray-800 hover:text-blue-500"
              onClick={toggleMenu}
            >
              Content
            </Link>
            <Link
            to="/guideline"
            className="flex items-center gap-2 w-full text-gray-800 hover:text-blue-500"
          >
            Guideline
          </Link>
            {token ? (
              <>
                <Link
                  to="/challenges"
                  className="flex items-center gap-2 w-full text-gray-800 hover:text-blue-500"
                  onClick={toggleMenu}
                >
               Test
                </Link>
                <Link
                  to="/certificates"
                  className="flex items-center gap-2 w-full text-gray-800 hover:text-blue-500"
                  onClick={toggleMenu}
                >
                  Certificates
                </Link>
                <Link
                  to="/profile"
                  className="flex items-center gap-2 w-full text-gray-800 hover:text-blue-500"
                  onClick={toggleMenu}
                >
                  Profile
                </Link>
                <button
                  onClick={() => {
                    toggleMenu();
                    handleLogout();
                  }}
                  className="flex items-center gap-2 text-red-500 hover:text-red-700 w-full"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className="flex items-center gap-2 text-blue-500 hover:text-blue-700 w-full"
                  onClick={toggleMenu}
                >
                  Login
                </Link>
                <Link
                  to="/register"
                  className="flex items-center gap-2 text-blue-500 hover:text-blue-700 w-full"
                  onClick={toggleMenu}
                >
                  Register
                </Link>
              </>
            )}
          </ul>
        </nav>
      )}
    </>
  );
};

export default Navbar;
