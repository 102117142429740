import React, { useState } from 'react';
import axios from 'axios';
import image from '../image/3293465.jpg';
import { ToastContainer, toast } from "react-toastify";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { POST_FORGETPASSWORD } from '../constant/constant';

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');

  const handleResetPassword = async () => {
    if (!email) {
      toast.warn("Please enter your email address.", { position: "top-right" });
      return;
    }

    try {
      const response = await axios.post(POST_FORGETPASSWORD, { email });

      if (response.status === 200) {
        Swal.fire({
          title: 'Success!',
          text: `A reset link has been sent to ${email}`,
          icon: 'success',
          confirmButtonText: 'OK',
          customClass: {
            popup: 'rounded-lg bg-[#f5f5f5]',
            title: 'text-[#bb0000] font-bold',
            confirmButton: 'bg-blue-600 text-white font-semibold px-4 py-2 rounded hover:bg-blue-700',
          }
        });

        // Clear the email field
        setEmail('');
      } else {
        Swal.fire({
          title: 'Oops!',
          text: 'Something went wrong, please try again.',
          icon: 'warning',
          confirmButtonText: 'Try Again',
          customClass: {
            popup: 'rounded-lg bg-[#ffefef]',
            title: 'text-[#ff0000] font-bold',
            confirmButton: 'bg-yellow-600 text-white font-semibold px-4 py-2 rounded hover:bg-yellow-700',
          }
        });
      }
    } catch (error) {
      console.error("Error:", error);

      // Display SweetAlert2 error message
      Swal.fire({
        title: 'Error!',
        text: 'Failed to send reset link. Please try again.',
        icon: 'error',
        confirmButtonText: 'OK',
        customClass: {
          popup: 'rounded-lg bg-[#fff3f3]',
          title: 'text-[#bb0000] font-bold',
          confirmButton: 'bg-red-600 text-white font-semibold px-4 py-2 rounded hover:bg-red-700',
        }
      });
    }
  };

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-gray-100 p-6">
      <ToastContainer />
      <h1 className="mb-6 text-3xl font-bold text-[#bb0000]">Forgot Password</h1>

      <img
        src={image}
        alt="Handwaving"
        className="mb-8 w-56 h-56 rounded-full object-cover"
      />

      <p className="mb-4 text-center text-gray-700">
        Enter your email address below, and we’ll send you a link to reset your password.
      </p>

      <div className="w-full max-w-sm">
        <input
          type="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="mb-4 w-full rounded-lg border border-gray-300 p-3 text-gray-700 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <button
          onClick={handleResetPassword}
          className="w-full rounded-lg bg-[#bb0000] p-3 text-white font-semibold hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all"
        >
          Send Reset Link
        </button>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
