import React from "react";
import Navbar from "../Navbar";
import Image from "../../image/ADVANCEMENT OF UNIT LEADERS.webp";
import Images from "../../image/Scouting-Supported-By-Adults-1.webp";
import Image1 from "../../image/Screenshot_9.jpg";
import backgroundImage from "../../image/newprofile.png";
import Pagination from "../Pagination";
const Content8 = () => {
  return (
    <>
      <div
        className=" h-[480px] text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Navbar />
      </div>

      <div className="px-32 py-20">
        <div className="lg:-mt-96 -mt-60">
          <div className="w-full justify-center items-center">
            <img className="w-full" src={Image} />
          </div>
        </div>
        <div className=" min-h-screen p-6">
          <header className=" pt-10 ">
          
          </header>

          {/* <main className="mt-6 space-y-6 text-gray-800">
            <div className="bg-[#1D57A5] px-2 py-4 rounded-tl-3xl  rounded-br-3xl">
              <h1 className="text-lg  px-5 text-white uppercase mt-2 text-start font-bold">
              BASIC COURSE FOR UNIT LEADER
              </h1>
            </div>
            <div className="-mt-7 text-md">
              <p>
                Before attending Basic Course for Unit Leader few subjects sent
                as a SLM to the candidates along with a selection letter for the
                Basic Course.
              </p>
            </div>

            <section>
              <div>
                The Basic Training Course is the first stage in the continuous
                Training of the Unit Leaders upto Himalaya Wood Badge Course.
                This provides the foundation to work successfully as Unit
                Leaders, through which the Scout / Guide spirit and enthusiasm
                is inculcated in the Unit Leaders. On completing the Basic
                Course successfully, he / she can take the responsibility as a
                Unit Leader of the particular section in the Scout / Guide
                Group. <br className="" />
                This is a formal Residential Course with two Modules i.e. SLM
                (Self Learning Modules) & RM (Residential Modules) for seven
                days continuously and separate for each section. The Basic
                Courses can also be conducted as Day Courses or Weekend Courses
                for seven days with compulsory three nights stay. Weekend
                Courses preferably consecutively and to be spread over a maximum
                period of two months. The Course is intended to train willing
                participant in his/her job as a Unit Leader. After successful
                completion of this Course, a person can work as a warranted
                Scouter / Guider in a Group of the Section concerned. The Course
                must be skill oriented and practical subjects should be more
                emphasized. Handouts must to be provided well in advance before
                the Course commences. Assessment during the Course should be
                effective in order to maintain standard. Course recognition and
                authorisation certificates are to be issued by the State
                Training Commissioner (Scouts). District level executives take
                initiative to organise this course. The minimum age will be 18
                years for Basic Cub Master / Flock Leader, Scout Master / Guide
                Captain and 21 years for Rover Scout Leaders / Ranger Leader.
                <br />
                For Cub Master / Lady Cub Master / Assistant C.M. / Asst. Lady
                C.M. : A man or woman must possess Matriculation or equivalent
                qualification. Scout Master / Lady Scout Master / Asst. S. M. /
                Asst. Lady S. M. : A man or woman must possess Intermediate
                (10+2) or equivalent qualification.
                <br />
                Rover Scout Leader: He should preferably possess the degree in
                any discipline or equivalent qualification. For Bunny Aunties: A
                woman must possess Matriculation or equivalent qualification.
                Flock Leaders / Assistant flock Leaders: A woman must possess
                Matriculation or equivalent qualification. Guide Captains /
                Assistant Guide Captains: A woman must possess Intermediate
                (10+2) or equivalent qualification. Ranger Leaders / Assistant
                Ranger Leaders: She should preferably possess the degree in any
                discipline or equivalent qualification.
                <br />
                Note: In exceptional cases, exemption from educational
                qualification may be granted by the State Chief Commissioner on
                the recommendation of concerned Assistant district Commissioner,
                district Commissioner and district Chief Commissioner.
              </div>
            </section>
          </main> */}
          {/* <main className="mt-6 space-y-6 text-gray-800">
            <div className="bg-[#1D57A5] px-2 py-4 rounded-tl-3xl  rounded-br-3xl">
              <h1 className="text-lg  px-5 text-white uppercase  text-start font-bold">
                ADVANCED COURSE FOR UNIT LEADERS
              </h1>
            </div>
            <div>
              This is a formal Residential course with two modules i.e. SLM & RM
              for 7 days continuously separate for each section.       <br />
               To undergo Advanced Course, a candidate should:        <br />
               1. Register his / her Unit &
              hold valid warrant for at least 3 months.        <br />
              2.Have completed six
              months after the Basic Course.       <br />
              <span className="font-semibold"> Note:</span> After successful completion
              of the Advanced Course, the Unit Leaders will be eligible to wear
              the HWB woggle.
            </div>

            <div className=" bg-[#1D57A5] px-2 py-4 rounded-tl-3xl  rounded-br-3xl">
              <h1 className="text-lg  px-5 text-white uppercase  text-start font-bold">
                TRAINING STUDY
              </h1>
            </div>
            <div>
              There are separate Training Studies containing a set of questions
              to be answered by candidates for Cub / Bulbul, Scout / Guide and
              Rover / Ranger Section. The Training Study will be revised every
              four years by DDSLT / DDGLT and sent to STC (S / G). The STC (S /
              G) shall arrange to send them to the concerned Scouter / Guuder
              directly under intimation to the DTC. The STC (S / G) of the state
              will prepare a panel of readers for evaluating the study of
              candidate, who has completed Advanced Course. After completion of
              all the formalities, the STC (S/G) will issue a certificate to the
              candidate under intimation to DDSLT / DDGLT and DTC (S / G).
            </div>
            <div className="bg-[#1D57A5] px-2 py-4 rounded-tl-3xl  rounded-br-3xl">
              <h1 className="text-lg  px-5 text-white uppercase  text-start font-bold">
                HIMALAYA WOOD BADGE COURSE FOR UNIT LEADER
              </h1>
            </div>
            <div>
              This is a formal Residential course with two modules i.e. SLM & RM
              for 7 days continuously separate for each section. The Himalaya
              Wood Badge Course is designed with a view to provide opportunities
              to consider in some depth, their own leadership role in the
              Movement and also to develop skills of leadership required in the
              Section concerned. It is for their progressive development keeping
              in view the changing needs and five training needs of Adult
              Leaders viz. Understanding Skills, Relationship Skills, Scouting
              /Guiding Skills, Planning Skills and Implementation Skills. It
              also gives an opportunity to share experiences with other leaders.<br/>

              In order to undergo Himalaya Wood Badge Course, a candidate should
              complete the following :<br/>
              1.  Should work at least for 12 months after
              the completion of the Advanced course successfully.<br/>
              2. He / She
              should successfully complete the Training Study issued by the NTC
              through STC (S / G). For Scout Wing - A Scouter should have at
              least two Tritiya Charan Cubs / Four Tritiya Sopan Scouts / Two
              Rajya Puraskar Rovers as the case may be to his / her credit and
              submit a certificate from District Organising Commissioner (S) /
              District Training Commissioner (S).<br/>
               <span className="font-semibold">OR</span><br/>
               4. For Guide Wing - A Guider
              should have at least two Swarna Pankh Bulbuls / Four Tritiya Sopan
              Guides / Two Rajya Puraskar Rangers as the case may be to her
              credit and submit a certificate from District Organising
              Commissioner (G) / District Training Commissioner (G).<br/>
              <span className="font-bold   ">Himalaya
              Wood Badge Parchment:</span> 
              <br/>
              <span className="font-semibold">FOR SCOUT WING -</span> On successful completion of
              HWB Course the HWB Parchment and Beads (A necklace of two wooden
              beads on black cord) signed by the Dy. Director of Scouts (Leader
              Training) should be issued directly to the participants along with
              the Course Certificate under intimation to STC (S). A declaration
              in a prescribed form issued by the NTC should be obtained from the
              candidates before the Course concludes. HWB Parchment and Beads
              shall remain with the individual as long as he / she continues to
              be a member of the organisation.<br/>
              <span className="font-semibold mt-4">FOR GUIDE WING -</span>  On successful
              completion of HWB Course the HWB Parchment signed by the Dy.
              Director Guides (Ldr. Trg.) and Pin should be issued directly to
              the participants along with the Course Certificate under
              intimation to STC (G). A declaration in a prescribed form issued
              by the NTC should be obtained from the candidates before the
              Course concludes. HWB Parchment and Pin shall remain with the
              individual as long as she continues to be a member of the
              Organisation.
            </div>
          </main> */}
          <div className="flex justify-center items-center">
            <img  src={Image1} className="rounded-xl " />
          </div>
          {/* <div className="w-full justify-center items-center">
            <img  src={Images} className="rounded-xl" />
          </div> */}
        </div>
        <Pagination/>
      </div>
    </>
  );
};

export default Content8;
