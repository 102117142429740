import React from "react";
import Navbar from "../Navbar";
import Image from "../../image/page6.jpg";
import Image1 from "../../image/Screenshot_10 (2).jpg";
import backgroundImage from "../../image/newprofile.png";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";
const Content6 = () => {
  return (
    <>
      <div
        className=" h-[480px] text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Navbar />
      </div>

      <div className="px-32 py-20">
        <div className="lg:-mt-96 -mt-60">
       
        <img src={Image} alt="Sign" className="w-full  mx-auto rounded-lg shadow-md" />
       
        </div>
        <div className=" p-6">
          <header className="mt-4">
          <img src={Image1} alt="Sign" className="w-full  mx-auto rounded-lg shadow-md"  />
            <h1 className="text-3xl font-bold uppercase text-blue-800 text-start mt-5">
            ORGANISATIONAL STRUCTURE AND STRUCTURE OF DISTRICT / DIVISIONAL ASSOCIATION
            </h1>
          </header>

        </div>
        <Pagination/>
      </div>
    </>
  );
};

export default Content6;
