import React from "react";
import Navbar from "../Navbar";
import Image from "../../image/the story of scouting guiding.webp";
import image from "../../image/The Story of Scouting and Guiding 1.webp";
import images from "../../image/The Story of Scouting and Guiding 2.webp";
import backgroundImage from "../../image/newprofile.png";
import Pagination from "../Pagination";

const Content1 = () => {
  return (
    <>
      <div
        className="h-[480px] text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Navbar />
      </div>

      <div className="px-4 md:px-8 lg:px-32 py-20">
        <div className="-mt-96">
          <div className="w-full flex justify-center items-center">
            <img
              className="w-full md:w-[1024px]"
              src={Image}
              alt="Scouting Story"
            />
          </div>
        </div>
        <div className="min-h-screen p-6">
          <header className="pt-2">
          
          </header>

          <main className="mt-6 space-y-6 text-gray-800">
            <section>
              <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
                Early Beginnings
              </h2>
              <p className="text-base sm:text-lg">
              All this began with 20 boys and an experimental camp in 1907. It
                 was held during the first nine days of August in 1907 at
                 Brownsea Island, near Poole in Dorset, England. The camp was a
                 great success and proved to its organiser, Robert Baden-Powell
                that his training and methods appealed to young people and
                 really worked. In January, 1908, Baden-Powell published the
                first edition of "Scouting for Boys". It was an immediate
                success and has since sold over 100 million copies, making it
                one of the bestselling books of all time. Baden-Powell had only
               intended to provide a method of training boys, something that
               existing youth organisations such as the Boys' Brigade and YMCA
                could adopt. To his surprise, youngsters started to organise
                themselves into what was to become one of the largest voluntary
               youth movements in the world.
              </p>
              <p className="text-base sm:text-lg">
              In January, 1908, Baden-Powell published the first edition of
                "Scouting for Boys". It was an immediate success and has since
                sold over 100 million copies, making it one of the bestselling
                books of all time.
              </p>
            </section>

            <section>
              <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
                Expansion of the Movement
              </h2>
              <p className="text-base sm:text-lg">
                The success of "Scouting for Boys" produced a Movement that
                quickly – automatically it seemed – adopted the name of The Boy
                Scouts. By 1909 "Scouting for Boys" had been translated into
                five languages, and a Scout rally in London attracted more than
                11,000 Scouts. As a result of Baden-Powell taking a holiday in
                South America, Chile was one of the first countries outside
                Britain to begin Scouting. In 1910 he visited Canada and the
                United States where it had already started. The coming of World
                War I in 1914 could have brought about the collapse of the
                Movement, but the training provided through the patrol system
                proved its worth. Patrol leaders took over when adult leaders
                volunteered for active service. Scouts contributed to the war
                effort in England in many ways; most notable perhaps were the
                Sea Scouts who took the place of regular coast-guardsmen.
              </p>
              <p className="text-base sm:text-lg">
                The first World Scout Jamboree took place in 1920 at Olympia in
                London with 8,000 participants, proving that young people from
                different nations could come together to share common interests
                and ideals.
              </p>
            </section>

            <div className="w-full flex justify-center items-center my-8">
              <img
                className="w-full"
                src={image}
                alt="Scouting and Guiding"
              />
            </div>

            <section>
              <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
                The Early Scout Programme
              </h2>
              <p className="text-base sm:text-lg">
                Scouting began as a programme for boys 11 to 18 years of age.
                Yet almost immediately others also wanted to participate. The
                Girl Guides programme was started in 1910 by Baden-Powell who
                designated his sister Agnes to manage it. In 1915 Robert
                Baden-Powell became Chairman of the Girl Guides Association, and
                his wife Olave, whom he married in 1912, became the new Chief
                Guide. A Wolf Cub section was formed in 1916 for younger boys.
                It used Rudyard Kipling's "Jungle Book", to provide an
                imaginative symbolic framework for activities. For older boys, a
                Rover Scout branch was formed in 1918.
              </p>
            </section>

            <section>
              <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
                The World Wars
              </h2>
              <p className="text-base sm:text-lg">
                Tasks – messengers, firewatchers, stretcher-bearers, salvage
                collectors and more.
              </p>
            </section>

            <section>
              <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
                The '60s, '70s, and '80s
              </h2>
              <p className="text-base sm:text-lg">
                Many countries gained their independence during these years.
                Scouting in developing countries gradually evolved to be a youth
                programme designed by Scout Leaders to better meet the needs of
                their communities. Scouts became more involved with issues such
                as child health, low-cost housing, literacy, food production,
                job skills training, and more.
              </p>
            </section>

            <section>
              <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
                Post Communistic Era
              </h2>
              <p className="text-base sm:text-lg">
                By the 1990s, Scouting had been reborn in every country where it
                existed prior to World War II, and it started throughout the
                newly independent countries of the Commonwealth of Independent
                States (formerly the USSR).
              </p>
            </section>

            <section>
              <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
                100 years and beyond
              </h2>
              <p className="text-base sm:text-lg">
                In 2007 the Movement celebrated its centenary - 100 years of
                Scouting, and in 2010 as 100 years of Guiding. What started as a
                small camp on Brownsea Island is today a growing Movement with
                members in nearly every country in the world. There are more
                than 40 million Scouts, young people and adults, in over 200
                countries and territories registered with the World Organization
                of Scout Movement (WOSM), and 10 million Guides registered with
                the World Association of Girl Guides and Girl Scouts (WAGGGS).
              </p>
            </section>

            <section>
              <h2 className="text-2xl sm:text-3xl font-semibold mb-4">
                Scouting / Guiding in India
              </h2>
              <p className="text-base sm:text-lg">
                Scouting started in India in 1909. On 7th November 1950, the Boy
                Scout Association in India and the Hindustan Scout Association
                merged under the name of The Bharat Scouts and Guides. The Girl
                Guide Association joined on 15th August 1951. The Bharat Scouts
                and Guides is a registered society under the Societies
                Registration Act, 1860, and is voluntary, non-political, and
                secular.
              </p>
            </section>
          </main>

          <div className="w-full flex justify-center items-center my-8">
            <img
              className="w-full md:w-full"
              src={images}
              alt="Scouting and Guiding Journey"
            />
          </div>
        </div>

        <Pagination />
      </div>
    </>
  );
};

export default Content1;
