import React from "react";
import Navbar from "../Navbar";
import Image from "../../image/lc3.png";
import Image1 from "../../image/img1.webp";
import Image2 from "../../image/img2.webp";
import Image3 from "../../image/img3.webp";

import backgroundImage from "../../image/newprofile.png";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";
const Content3 = () => {
  const videoUrl = "https://www.youtube.com/embed/7E8iKRs1TM0";
  return (
    <>
      <div
        className="h-[480px] text-white"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Navbar />
      </div>

      <div className="px-4 md:px-16 py-6 md:py-20">
        <div className="-mt-80 md:-mt-96">
          <div className="w-full max-w-full md:w-[1024px] flex justify-center items-center mx-auto">
            <img
              className="mx-auto w-full max-w-[600px] md:max-w-[1024px]"
              src={Image}
              alt="Organization Image"
            />
          </div>
        </div>

        <div className="min-h-screen p-6">
          <header className="pt-2">
            <h1 className="text-2xl font-bold uppercase text-red-600 mt-2 text-start">
              TWO WINGS OF THE ORGANISATION SCOUT WING AND GUIDE WING
            </h1>
            <h1 className="text-lg font-normal uppercase mt-2 text-start">
              IN EACH WING THERE ARE THREE SECTIONS. THE DETAILS ARE AS FOLLOWS:
            </h1>
          </header>

          <main className="mt-6 space-y-6 text-gray-800">
            <div className="bg-[#0057A8] px-4 py-4 md:px-6 md:py-6 rounded-tl-3xl rounded-br-3xl">
              <h1 className="text-lg px-5 text-white uppercase mt-2 text-start font-bold">
                THE SECTIONS OF SCOUT WING AND THE UNITS ARE -
              </h1>
            </div>
            <div className="flex justify-around">
              <section>
                <h2 className="text-xl font-bold mb-2">1. CUB SECTION</h2>
                <p className="text-base font-normal">
                  Age: Between 5+ to 10 Years
                </p>
                <p className="text-base font-normal">Unit: Pack</p>
                <p className="text-base font-normal">Leader: Cub Master</p>
                <p className="text-base font-normal">
                  Unit Size: 12 to 24 boys
                </p>
                
              </section>
              <section>
                <h2 className="text-xl font-bold mb-2">2. SCOUT SECTION</h2>
                <p className="text-base font-normal">
                  Age: Between 10+ to 17 Years
                </p>
                <p className="text-base font-normal">Unit: Troop</p>
                <p className="text-base font-normal">Leader: Scout Master</p>
                <p className="text-base font-normal">
                  Unit Size: 12 to 32 boys
                </p>
              </section>
              <section>
                <h2 className="text-xl font-bold mb-2">3. ROVER SECTION</h2>
                <p className="text-base font-normal">
                  Age: Between 15+ to 25 Years
                </p>
                <p className="text-base font-normal">Unit: Crew</p>
                <p className="text-base font-normal">
                  Leader: Rover Scout Leader
                </p>
                <p className="text-base font-normal">Unit Size: 6 to 24 boys</p>
              </section>
              
            </div>
            <img src={Image1} alt="" className="rounded-xl"/>
          </main>

          <main className="mt-6 space-y-6 text-gray-800">
            <div className="bg-[#0057A8] px-4 py-4 md:px-6 md:py-6 rounded-tl-3xl rounded-br-3xl">
              <h1 className="text-lg px-5 text-white uppercase mt-2 text-start font-bold">
                THE SECTIONS OF GUIDE WING AND THE UNITS ARE -
              </h1>
            </div>
            <div className=" flex justify-around">
              <section>
                <h2 className="text-xl font-bold mb-2">1. BULBUL SECTION</h2>
                <p className="text-base font-normal">
                  Age: Between 5+ to 10 Years
                </p>
                <p className="text-base font-normal">Unit: Flock</p>
                <p className="text-base font-normal">Leader: Flock Leader</p>
                <p className="text-base font-normal">
                  Unit Size: 12 to 24 girls
                </p>
              </section>
              <section>
                <h2 className="text-xl font-bold mb-2">2. GUIDE SECTION</h2>
                <p className="text-base font-normal">
                  Age: Between 10+ to 17 Years
                </p>
                <p className="text-base font-normal">Unit: Guide Company</p>
                <p className="text-base font-normal">Leader: Guide Captain</p>
                <p className="text-base font-normal">
                  Unit Size: 12 to 32 girls
                </p>
              </section>
              <section>
                <h2 className="text-xl font-bold mb-2">3. RANGER SECTION</h2>
                <p className="text-base font-normal">
                  Age: Between 15+ to 25 Years
                </p>
                <p className="text-base font-normal">Unit: Ranger Team</p>
                <p className="text-base font-normal">Leader: Ranger Leader</p>
                <p className="text-base font-normal">
                  Unit Size: 6 to 24 girls
                </p>
              </section>
            </div>
            <img src={Image2} alt="" className="rounded-xl"/>
          </main>

          <main className="mt-6 space-y-6 text-gray-800">
            <div className="bg-[#0057A8] px-4 py-4 md:px-6 md:py-6 rounded-tl-3xl rounded-br-3xl">
              <h1 className="text-lg px-5 text-white uppercase mt-2 text-start font-bold">
                THERE IS A COMMON SECTION IN BOTH THE WINGS CALLED BUNNY
              </h1>
            </div>
            <section>
              <h2 className="text-xl font-bold  mb-2">BUNNY SECTION</h2>
              <p className="text-base font-normal">
                Age: Between 3+ to 5 Years
              </p>
              <p className="text-base font-normal">Unit: Tamtola</p>
              <p className="text-base font-normal">Leader: Bunny Aunty</p>
              <p className="text-base font-normal">
                Unit Size: Maximum 20 kids
              </p>
            </section>
            <img src={Image3} alt="" className="rounded-xl"/>
          </main>
        </div>

        <div className="max-w-full h-auto py-8 flex justify-center items-center">
          <div className="relative w-full" style={{ paddingTop: "56.25%" }}>
            <iframe
              src={videoUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="absolute top-0 left-0 w-full h-full shadow-xl rounded-xl"
            ></iframe>
          </div>
        </div>
        <Pagination />
      </div>
    </>
  );
};

export default Content3;
