import React from "react";
import {
  FaInstagram,
  FaMapMarkerAlt,
  FaTwitter,
  FaFacebook,
  FaYoutube,
  FaWhatsapp,
  FaShoppingCart,
} from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import bsgLogo from "../image/smalllogo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-[#1D57A5] text-white bottom-0 relative ">
      <div className="container mx-auto px-6 md:px-12 lg:px-20">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0 ">
          <div className="flex flex-col items-center md:items-start text-center md:text-left md:w-1/3 py-6">
            <img src={bsgLogo} alt="BSG Logo" className="w-36" />
            <p className="text-white mt-5 text-base">
              The Bharat Scouts and Guides,
              <br /> National Headquarters,  <br /> Lakshmi Mazumdar Bhawan,
              <br />
             16 Mahatma Gandhi Marg, I. P. Estate,
              <br /> New Delhi - 110002
            </p>
            <div className="flex space-x-4 mt-6">
              <Link
                to="https://www.instagram.com/bsgindia/"
                target="_blank"
                aria-label="Instagram"
                className="text-white text-2xl hover:text-pink-400 transition-colors"
              >
                <FaInstagram />
              </Link>
              <Link
                to="https://www.google.co.in/maps/place/Bharat+Scouts+and+Guides+National+Headquarters/@28.6230587,77.2470711,17z/data=!3m1!4b1!4m6!3m5!1s0x390ce32c87b1fb89:0xec699c8b9de963f2!8m2!3d28.6230587!4d77.2470711!16s%2Fg%2F1pty22rfb?entry=ttu&g_ep=EgoyMDI0MTEyNC4xIKXMDSoASAFQAw%3D%3D"
                aria-label="Location"
                target="_blank"
                className="text-white text-2xl hover:text-red-300 transition-colors"
              >
                <FaMapMarkerAlt />
              </Link>
              <Link
                to="https://x.com/bsgnhq"
                target="_blank"
                aria-label="Twitter"
                className="text-white text-2xl hover:text-blue-400 transition-colors"
              >
                < FaSquareXTwitter  />
              </Link>
              <Link
                to="https://www.facebook.com/bsgnhq"
                target="_blank"
                aria-label="Facebook"
                className="text-white text-2xl hover:text-blue-500 transition-colors"
              >
                <FaFacebook />
              </Link>
              <Link
                to="https://www.youtube.com/@BSGIndia"
                target="_blank"
                aria-label="YouTube"
                className="text-white text-2xl hover:text-red-500 transition-colors"
              >
                <FaYoutube />
              </Link>
              <Link
                to="https://shop.bsgindia.org"
                aria-label="Cart"
                className="text-white text-2xl hover:text-yellow-400 transition-colors"
              >
                <FaShoppingCart />
              </Link>
            </div>
          </div>
          <div className="md:w-1/3 text-center md:text-left flex justify-center items-start flex-col space-y-4">
            <h3 className="font-bold text-lg  text-gray-100">Contact Us</h3>
            <p className="text-white">
              🌐{" "}
              <Link
                to="https://www.bsgindia.org"
                target="_blank"
                className="hover:text-white transition-colors"
              >
                bsgindia.org
              </Link>
            </p>
            <p className="text-white">📧 Email: info@bsgindia.org</p>
            <p className="text-white">
              <span className="text-blue-500">📘</span> Phone: (011) 23370724,
              23378667
            </p>
          </div>
          <div className="md:w-1/3 text-center md:text-left flex justify-center items-start flex-col space-y-5">
            <h3 className="font-bold text-lg mb-3 text-gray-100">
              Subscribe to Our Newsletter
            </h3>
            <input
              type="email"
              placeholder="Enter Your Email"
              className="mt-2 p-2  rounded-lg border-0 bg-white text-gray-800 placeholder-gray-400 focus:ring-2 focus:ring-yellow-500 w-[340px]"
            />
            <button className="mt-4  bg-slate-200 text-sky-700 py-2 rounded-lg hover:bg-slate-400 transition-all font-mono w-[340px]">
              Subscribe
            </button>
          </div>
        </div>
      </div>
      <div className="bg-[#0C3770] flex justify-between text-sm font-sans items-center p-3">
        <div>
          <p className=" text-white">
            Copyright © 2024 All rights reserved
          </p>
        </div>
        <div>
          <p className=" text-white">
            Privacy Policy | Terms and Conditions
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;