import React from "react";
import Navbar from "./Navbar";
import Image1 from "../image/the story of scouting guiding.webp";
import Image2 from "../image/WHAT IS SCOUTING GUIDING.webp";
import Image3 from "../image/lc3.png";
import Image4 from "../image/THE PROMISE AND LAW.webp";
import Image5 from "../image/MOTTO, SIGN, SALUTE AND LEFT HAND SHAKE.webp";
import Image6 from "../image/ORGANISATIONAL STRUCTURE AND STRUCTURE OF DISTRICT  DEVISIONAL ASSOCIATION.webp";
import Image7 from "../image/HOW TO START AND REGISTER UNIT & CONDUCT OF UNIT MEETINGS.webp";
import Image8 from "../image/ADVANCEMENT OF UNIT LEADERS.webp";

import { Link } from "react-router-dom";

const LearningContent = () => {
  return (
    <>
      <div className="bg-[#1D57A5] h-56 text-white">
        <Navbar />
        <h1 className="font-semibold text-4xl text-center text-white">
          LEARNING CONTENT
        </h1>
      </div>

      <div className="p-11">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 lg:-mt-20 md:-mt-20 ">
          <div className="w-full bg-white border  border-gray-200 shadow-md rounded-t-[300px] rounded-b-3xl dark:bg-gray-800 dark:border-gray-700">
            <img
              className="h-auto object-top object-cover w-full -mt-[1px]"
              src={Image1}
            />
            <div className="p-4 text-center flex flex-col justify-between">
              <Link
                to="/content/1"
                className="px-12 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Start
              </Link>
            </div>
          </div>
          <div className="w-full bg-white border  border-gray-200 shadow-md rounded-t-[300px] rounded-b-3xl dark:bg-gray-800 dark:border-gray-700">
            <img
              className="h-auto object-top object-cover w-full -mt-[1px]"
              src={Image2}
            />
            <div className="p-4 text-center flex flex-col justify-between">
              <Link
                to="/content/2"
                className="px-12 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Start
              </Link>
            </div>
          </div>
          <div className="w-full bg-white border  border-gray-200 shadow-md rounded-t-[300px] rounded-b-3xl dark:bg-gray-800 dark:border-gray-700">
            <img
              className="h-auto object-top object-cover w-full -mt-[1px]"
              src={Image3}
            />
            <div className="p-4 text-center flex flex-col justify-between">
              <Link
                to="/content/3"
                className="px-12 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Start
              </Link>
            </div>
          </div>
          <div className="w-full bg-white border  border-gray-200 shadow-md rounded-t-[300px] rounded-b-3xl dark:bg-gray-800 dark:border-gray-700">
            <img
              className="h-auto object-top object-cover w-full -mt-[1px]"
              src={Image4}
            />
            <div className="p-4 text-center flex flex-col justify-between">
              <Link
                to="/content/4"
                className="px-12 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Start
              </Link>
            </div>
          </div>
          <div className="w-full bg-white border  border-gray-200 shadow-md rounded-t-[300px] rounded-b-3xl dark:bg-gray-800 dark:border-gray-700">
            <img
              className="h-auto object-top object-cover w-full -mt-[1px]"
              src={Image5}
            />
            <div className="p-4 text-center flex flex-col justify-between">
              <Link
                to="/content/5"
                className="px-12 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Start
              </Link>
            </div>
          </div>
          <div className="w-full bg-white border  border-gray-200 shadow-md rounded-t-[300px] rounded-b-3xl dark:bg-gray-800 dark:border-gray-700">
            <img
              className="h-auto object-top object-cover w-full -mt-[1px]"
              src={Image6}
            />
            <div className="p-4 text-center flex flex-col justify-between">
              <Link
                to="/content/6"
                className="px-12 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Start
              </Link>
            </div>
          </div>
          <div className="w-full bg-white border  border-gray-200 shadow-md rounded-t-[300px] rounded-b-3xl dark:bg-gray-800 dark:border-gray-700">
            <img
              className="h-auto object-top object-cover w-full -mt-[1px]"
              src={Image7}
            />
            <div className="p-4 text-center flex flex-col justify-between">
              <Link
                to="/content/7"
                className="px-12 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Start
              </Link>
            </div>
          </div>
          <div className="w-full bg-white border  border-gray-200 shadow-md rounded-t-[300px] rounded-b-3xl dark:bg-gray-800 dark:border-gray-700">
            <img
              className="h-auto object-top object-cover w-full -mt-[1px]"
              src={Image8}
            />
            <div className="p-4 text-center flex flex-col justify-between">
              <Link
                to="/content/8"
                className="px-12 py-2 text-sm font-medium text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                Start
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LearningContent;
