import React, { useState } from "react";
import Navbar from "./Navbar";
import backgroundImage from "../image/login.png";
import { Link, useNavigate } from "react-router-dom";
import image from "../image/shape.png";
import axios from "axios";
import { POST_USER_lOGIN } from "../constant/constant";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(POST_USER_lOGIN, {
        email,
        password,
      });

      if (response.data.token) {
        const setSessionItemWithExpiry = (key, value, expiryInMinutes) => {
          const now = new Date();
          const expiryTime = now.getTime() + expiryInMinutes * 120 * 1000;
          const item = {
            value,
            expiry: expiryTime,
          };
          sessionStorage.setItem(key, JSON.stringify(item));
        };
        setSessionItemWithExpiry("token", response.data.token, 60);
        setSessionItemWithExpiry("userId", response.data.userId, 60);
        navigate("/");  
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "An error occurred.");
    }finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative h-[884px]">
       {loading && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      )}
      <div
        className="absolute inset-0"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>

      <div className="absolute top-0 left-0 right-0 items-center">
        <Navbar />
        <div className="relative flex flex-col md:flex-row justify-between items-center h-full px-4 md:px-10 mt-16">
       
          <div className="text-white max-w-lg md:w-1/2 text-center md:text-left mb-8 md:mb-0">
            <h1 className="text-3xl sm:text-4xl font-bold mb-4">Introduction</h1>
            <p className="text-lg sm:text-xl font-semibold">
              Welcome to Online Beginners Course of <br />the Bharat Scouts
              and Guides.
            </p>
          </div>

      
          <div
            className="flex justify-center items-center"
            style={{
              backgroundImage: `url(${image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              width: "100%",
              maxWidth: "600px",
              height: "auto",
              padding: "2rem",
              borderRadius: "1rem",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="w-full max-w-sm p-8 bg-white rounded-lg shadow-lg">
              <h2 className="text-2xl font-bold mb-4">Login</h2>
              {errorMessage && (
                <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
              )}
              <p className="text-black font-semibold mb-6">Login to your account</p>
              <form onSubmit={handleLogin}>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter your password"
                  />
                  <span className="text-sm text-red-600 hover:underline cursor-pointer mt-2 block text-right">
                          <Link to="/forgot-password">Forgot Password?</Link>
                      </span>
                </div>
                <button
                  type="submit"
                  className="w-full py-2 px-4 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600"
                >
                  Login
                </button>
              </form>
              <p className="mt-6 text-lg text-gray-800">
                <span className="mr-2">Not having account?</span>
                <Link
                  to="/register"
                  className="text-blue-600 hover:text-blue-700 font-semibold transition-all duration-300"
                >
                  Create a New Account
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
