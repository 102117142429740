import React from "react";
import image1 from "../image/cnc.png";
import backgroundImage from "../image/background.jpg";
const Intro1 = () => {
  return (
    <div
      className="h-auto flex flex-col md:flex-row items-center justify-center p-4 md:p-6"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="p-4 md:p-8 w-full md:w-1/2">
        <h1 className="text-yellow-400 text-2xl sm:text-3xl md:text-4xl font-bold mb-4">
          Greetings to all !!
        </h1>
        <div className="border-t-4 border-white w-24 sm:w-32 mb-4"></div>
        <p className="text-sm sm:text-md md:text-lg text-white mb-4">
          I am delighted to introduce the "Online Beginners Course" designed
          especially for you. In today’s digital era, where everything is at
          your fingertips, why should BSG members be left behind? This
          initiative aims to inspire and empower young adults to join the Bharat
          Scouts and Guides Movement and establish their own units upon
          successfully completing this course
        </p>
        <p className="text-sm sm:text-md md:text-lg text-white mb-4">
          Our Movement focuses on the holistic development of young
          people—enhancing their physical, intellectual, emotional, social, and
          spiritual growth while nurturing responsible citizens. No one should
          miss the opportunity to be part of this transformative journey. As an
          adult, you too can play a vital role. In the words of our Founder,
          “You make a living by what you get, but you make a life by what you
          give.” Let us come together to contribute to this noble cause and make
          a difference in the simplest way possible.
        </p>
        <p className="text-sm sm:text-md md:text-lg text-white mb-4">
        We look forward to bring in more and more members under the umbrella of BSG. </p>
        <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mt-4 text-white">
        Best wishes!        </h2>
        <p className="text-base sm:text-2xl md:text-xl font-bold mt-4 text-right text-white">
          Dr. K. K. Khandelwal, IAS(R)
        </p>
        <h5 className="text-base sm:text-2xl md:text-xl font-bold text-right text-white">
          Chief National Commissioner
        </h5>
      </div>

      <div className="w-full md:w-1/2 flex items-center justify-center p-4">
        <img src={image1} alt="Scouts Guide" className="w-80 sm:w-96 " />
      </div>
    </div>
  );
};

export default Intro1;
